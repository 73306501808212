@import (reference) '~rev-less/global/variables';

.vbBtn {
	composes: btn from global;//'bootstrap/dist/css/bootstrap.css';

	border-radius: 3px;
	font-size: @type14;
	font-weight: 400;
	line-height: @type16;
	padding: 8px 12px;
	position: relative;
	top: 0;

	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
}

.vbBtnTransparent {
	background: none;
	border: none !important;
	box-shadow: none;
}

.vbIconBtn {
	composes: vbBtn;

	:global(.glyphicons) {
		margin-right: 3px;
		margin-left: 0;
		font-size: @type11; //this is to get the height of the button down to the same size as a non-icon button
	}
}