@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

vb-toolbar
{

	width: 100%;

	&.mobile-nav,
	&.section-header {

		// modal headers, large content sections

		height: @xlargeRow;
		color: @color1MedText;

		h1 {
			font-weight: bold;
			font-size: @type18;
			text-transform: uppercase;
			color: @color1MedText;
			.TextClip();
			margin: 0;
		}
	}

	&.panel-sub-header,
	&.sub-section-header {

		height: @mediumRow;
		color: @color1MedText;
		border-top: 3px solid @borderColor;

		h2 {
			font-size: @type14;
			font-weight: bold;
			color: @color1MedText;
			text-transform: uppercase;
			margin: 0;
		}
	}

	&.panel-sub-header {
		border-top: none;
		border-bottom: 1px solid @borderColorLight;
	}

	.btn:not(.btn-primary):not(.btn-open-in-new-window):not(.active) {
		background: none;
		border-color: @borderColor;
	}

	.btn-white {
		color: @color1LightText;

		&.active {
			.BtnWhiteActive;

			.glyphicons {
				color: @accentDarken20;
			}
		}
	}

	.search-input {
		.flexDisplay;
		.flexJustifyContent(flex-end);

		form {
			min-width: 150px;
			max-width: 300px;
		}
	}

	&.mobile-nav {
		display: none;
	}

	@media(max-width: 991px){

		&.mobile-nav {
			display: block;
			width: 100%;
			height: @largeRow;
			background: @color1;
			color: @color1LightText;

			h1 {
				color: @color1LightText;
			}

			.btn {
				border: none;

				&:hover {
					color: @white;
				}

				&.btn-back {
					padding: 0 @typeUnit1x;
				}
			}

			&.bottom {
				.AbsoluteFill;
				top: auto;
				height: @largeRow;
				background: @white;
				border-top: 1px solid @borderColorDark;
				border-bottom: none;
				z-index: 10000;

				.btn {

					font-size: @type16;
					color: @color1;

					&:hover {
						color: @color1;
					}
				}

				&.active {

					border: none;

					.btn {
						color: @color1;
					}
				}
			}
		}
	}
}
