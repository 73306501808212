[layout-padding] {
	&[layout-padding-no-edges] {
		> vb-layout:first-child,
		> [flex]:first-child,
		> div:first-child {
			padding-left: 0;
		}
		> vb-layout:last-child,
		> [flex]:last-child,
		> div:last-child {
			padding-right: 0;
		}
	}
}

[layout-padding="025"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 4px;
	}
}
[layout-padding="0 050"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 0 8px;
	}
}
[layout-padding="0 100"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 0 16px;
	}
}

[layout-padding="025 100"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 4px 16px;
	}
}
[layout-padding="050 025"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 8px 4px;
	}
}
[layout-padding="050 100"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 8px 16px;
	}
}
[layout-padding="100 025"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 16px 4px;
	}
}
[layout-padding="100"] {
	> div, > label, > vb-layout, > [flex] {
		padding: 16px;
	}
}
[navbar-padding="100"] {

	// delete this rule

	> div, > label, > vb-layout, > [flex] {
		padding: 0 16px;
	}
}