@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@dropdownMenuBoxShadow: 1px 1px 1px 1px rgba(48, 48, 48, .5);

.dropDown {
	position: relative;
	display: inline-block;
}
.dropDownButton {
	.BoxShadow(@dropdownMenuBoxShadow);
	font-size: @type16;
	padding: .5em;
}

.dropDownContent {
	background: @color1Lighten20;
	.RoundedShape(4px);
	border: 1px solid @lightBorder;
	.BoxShadow(@dropdownMenuBoxShadow);
	overflow: hidden;
	padding: 0;
	position: absolute;
	min-width: 150px;
	z-index: 100;

	.listWrap {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
}

.alignMenuRight {
	right: 0;
}
