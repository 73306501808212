@import  (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

@playerHeight: var(--player-videogular-height);
@playerWidth: var(--player-videogular-width);

@viewerIdWrapperHeight: var(--viewer-id-content-height);
@viewerIdWrapperWidth: var(--viewer-id-content-width);
@viewerIdFontSize: var(--viewer-id-font-size);
@viewerIdCustomTextFontSize: var(--viewer-id-custom-txt-font-size);
@viewerIdMaxWidth: 400px;

@pushContentWrapperHeight: var(--push-content-height);
@pushContentWrapperVerticalPadding: var(--push-content-wrapper-vertical-padding);
@pushContentDetailsVerticalPadding:var(--push-content-details-vertical-padding);
@pushContentNameFontSize: var(--push-content-name-font-size);
@pushContentLinkFontSize: var(--push-content-link-font-size);

.pushContentContainer {
	display: flex;
	flex-direction: column;
	padding: @pushContentWrapperVerticalPadding 10px 0px 0px;
	position: absolute;
	right: 0px;
	width: 30%;
	max-width: @viewerIdMaxWidth;

	a:hover, a:active, a:focus{
		text-decoration: none;
	}

	a, a:visited {
		color: @white;
	}
}

.pushContentWrapper {
	z-index: 100000; // 1 higher than the default class
}

.pushContent {
	padding-top: @pushContentWrapperVerticalPadding;
	position: relative;
	background: rgba(0,0,0,.8);
	background-clip: content-box;
	-webkit-text-stroke: 0 !important;
	height: @pushContentWrapperHeight;
	pointer-events: auto !important;
}

.pushContentClose {
	float: right;
	background-color: black;
	background-clip: content-box;
	padding: 3px 3px 0px 0px;
}

.pushContentName {
	font-size: @pushContentNameFontSize;
	padding: @pushContentDetailsVerticalPadding 0px @pushContentDetailsVerticalPadding 10px;
	color: @white;
	-webkit-text-fill-color: @white;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	line-height: normal;
}

.pushContentLink {
	-webkit-text-fill-color: @accentColor;
	color: @accentColor;
	font-size: @pushContentNameFontSize;
	padding: 0px 0px @pushContentDetailsVerticalPadding 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
