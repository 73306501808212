.no-padding {
	padding: 0 !important;
}

.no-margin {
	margin: 0 !important;
}

/* Need this because it is on the body level of the DOM
 * If this needs to be overriden then one will need to use !important
 */
 .tooltip {
	 z-index: 2000;
 }
