@import "global/mixins";
@import "global/variables";

vb-toolbar .search-input,
.combined-toolbar .search-query-field {
	.input-group {
		border: 1px solid @borderColor;
		.RoundedShape();
		background-color: @accentColor05;
		.TransitionAll(background .3s, border-color .3s;);

		.form-control,
		.btn,
		.admin-multiselect & .btn,
		.admin-multiselect & .btn:hover {
			border: none;
			background: none;
			.BoxShadow(none);
		}

		.form-control,
		.input-group-highlighted,
		.form-control:focus:not(:active):not(.active) {
			.BoxShadow(none);
			background: none;
		}
	}

	&.active .input-group {
		border-color: @accentColor;
		background-color: @white;

		.form-control {
			font-weight: bold;
			color: @color1;
		}
		
		.btn .glyphicons {
			color: @accentColor;
		}
	}
}

.admin-multiselect .combined-toolbar {
	.input-group {
		border: none;
		.RoundedShape(@typeUnit1x);
		background-color: fade(@black, 20%);
		color: @whiteFade75;
	}

	.search-query-field.active .input-group {
		background: @color1;

		.form-control,
		.btn .glyphicons {
			color: @accentColor05;
		}
	}
}