@import (reference) '~rev-less/global/variables';

.root {
	:global(.ag-root-wrapper) {
		flex: 1 1 auto !important;
		order: -1;
		position: relative;
	}

	&:not(:global(.vbUiGridAutoHeight)) {

		:global(.ag-root-wrapper-body) {
			bottom: 0;
			height: 100%;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			width: 100%;
		}

	}

	// Safari's scroll bars strangely just aren't visible (apart from the usual weird hover behavior),
	// so work around that by providing custom styling
	:global(.ag-body-viewport-wrapper.ag-layout-normal) {
		overflow-x: scroll;
		overflow-y: scroll;
	}

	::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 8px;
		height: 8px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: var(--vb-ui-ag-grid-container-scroll-color , rgba(0,0,0,.5));
		box-shadow: 0 0 1px rgba(255,255,255,.5);
	}

	:global(.ag-selection-checkbox) {
		height: 46px;;
	}

	:global(.ag-checkbox-input-wrapper:after) {
		color: @lightBorder;
	}

	:global(.ag-checkbox-input-wrapper.ag-checked::after) {
		color: @accentColor; //check later with theming.
	}

}

.columnPickerIcon {
	font-size: @type20;
	top: 3px;
}

.hasHeaderTooltipValue {
	:global(.ag-header-cell-label:after) {
		content: '\E195';
		color: var(--vb-ui-ag-grid-header-tooltip-icon-color , @color1Fade75);
		margin-left: 5px;
		height: 25px;
		width: 25px;
		font-family: 'Glyphicons Regular';
		position: relative;
		top: 3px;
		font-size: 14px;
	}
}
