@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

//todo: Workaround for a rendering issue. fixed in ui-bootstrap 1.1.2. Remove this when we update ui-bootstrap.
[uib-modal-window]:not(.modal) {
	display: none;
}

.modal {
	top: 0;
	bottom: 0;

	&-view {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 1040;
		overflow: auto;
		.VerticalGradient(rgb(155,160,163), rgb(89,96,101), 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzliYTBhMyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM1OTYwNjUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
		background-repeat: no-repeat;
		background-attachment: fixed;
	}

	&-open {
		body&,
		.navbar-fixed-top,
		.navbar-fixed-bottom {
			margin-right: 0;
		}
	}

	&-backdrop
	{
		background: @adminGrayFade75;
		opacity: 0;
	}
	&-backdrop.in,
	&-backdrop.fade.in {
		opacity: 1;
	}
	&-content {
		background: #e9e9e9;
		color: @color1;
		overflow: hidden;
		.RoundedShape();

		&.modal-table {
			background: @white;
		}

		.video-edit &,
		.ie-modal-player & {
			background-color: transparent;
			border: none;
			.BoxShadow(none);
		}

		vb-toolbar {
			&.section-header-warning {
				background-color: @warningColor;

				h1 {
					color: @white;
				}

				.glyphicons {
					padding-left: 2px; //prevents icon from being slightly cut off
				}
			}
		}
	}

	@closeButtonSize: 48px;
	&-header {
		border: none;
		border-bottom: 1px solid @blackFade10;
		background: none repeat scroll 0 0 @blackFade10;
		padding: @type8;

		&.no-border {
			border-bottom: none;
		}

		.icon-style {
			display: inline-block;
			float: left;
			vertical-align: middle;
			line-height: 1.4;
			margin-right: 5px;

			&[icon-type="error"] {
				color: @warningColor;
			}
		}

		// H3: media import modals
		// H4: ok cancel modals

		h1,
		h2,
		h3,
		h4 {
			vertical-align: middle;
			color: @color1MedText;
			text-transform: uppercase;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			line-height: 1.4;
			display: inline-block;
		}

		h2 {
			margin-top: 4px;

			.group.pull-right,
			.close.btn.pull-right
			{
				position: relative;
				top: -4px;
				font-size: @type16;
				margin: 0;
			}
		}

		h4 {
			padding-right: @closeButtonSize;

			.btn.close {
				position: absolute;
				right: 0;
				top: 0;
			}
		}

		.circle_exclamation_mark {
			top: 3px;
			margin-right: 4px;
		}

		.close {
			margin-top: 0;
			height: @closeButtonSize;
			width: @closeButtonSize;
			right: -16px;
		}
	}

	&-body {
		padding: 15px;
		z-index: 1;

		&-scroll-300px {
			max-height: 300px;
			overflow: auto;
		}

		&-no-padding {
			padding: 0;
		}

		.well { //not specific to webcast
			margin-bottom: 5px;
		}
	}

	&-footer {
		padding: 12px 15px;
		border-top: none;
	}

	@modal-vertical-centered-transform: translate(0, 50%);
	&-vertical-centered {
		.Transform(@modal-vertical-centered-transform) !important;
	}
}

.modal.in.centered-modal {
	height: 100%;

	.modal-dialog {
		.VerticalCenter;
		margin: 0 32px;

		@media(min-width: 768px) {
			margin: 0 auto;
		}
	}
}

.ie-modal-player {
	margin-right: -15px;
	padding: 0 15px;
	.PointerCursor;

	.modal-dialog {
		left: 0;
		right: 0;
		width: inherit;
		padding: 48px 0 0 0;
		margin-left: 8.33%;
		width: 83.33%;
	}

	&-fs {
		width: 100%;

		.modal-dialog {
			left: 0;
			right: 0;
			width: inherit;
			padding: 0;
			margin-left: 0;
			width: 100%;
		}
	}
}

.modalWrapper {
	position: absolute;
	top: 50% !important;
	.Transform(translate(0, -50%)) !important;
	margin: auto 10%;
	width: 80%;
	height :80%;
}

.model-body-styles {
	.modal-content {
		&:extend(.AbsoluteFill);
		min-height: 100%;
	}

	.modal-body {
		position: absolute;
		top: 64px;       /* height of header */
		bottom: 64px;    /* height of footer */
		left: 0;
		right: 0;
		overflow-y: auto;
	}

	.modal-footer {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.modal.fluid {
	.modal-dialog {
		.modalWrapper;
	}
	.model-body-styles;
}

//ngx-bootstrap support
.modal {
	.modal-dialog.fluid {
		.modalWrapper;
		.model-body-styles;
	}
}

.admin .modal-body-no-padding {
	padding: 0;
	.RoundedBottomShape(3px);

	.admin-table {
		border: none;
	}

	.table-tools {
		margin: 0;
		padding: 15px;
		background: #596065;
		border-bottom: 1px solid fade(@black, 20%);
	}

	.table-bordered {
		border-right: 0;
		.RoundedBottomShape(3px);

		> thead,
		> tbody,
		> tfoot {
			th,
			td {
				padding: 8px 15px;
			}
		}

		tr:last-child {
			th,
			td {
				border-bottom: none;
			}
		}
	}
}

.media {
	&.modal:focus {
		outline: none;
	}

	.modal-dialog {
		padding-top: 100px;
	}
}

.fake-modal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.btn-close-modal {
	position: absolute;
	top: 15px;
	left: 15px;
}

@media (min-width: 768px){
	.admin .modal-dialog {
		margin: 10vh auto;
	}

	.ie-modal-player-fs .modal-dialog {
		margin: 0 auto;
	}
}


@media (min-width: 1200px) {
	.ie-modal-player {
		padding-right: 30px;
		padding-left: 30px;

		&-fs {
			padding-right: 0;
			padding-left: 0;
		}
	}
}
