@import "../global/variables";
@import "../global/mixins";

// used for playlist and collection tiles with multiple thumbnails

.grid-item-list {
	overflow: hidden;
	.RoundedTopShape(3px);
	background: @white;

	&:hover {
		&:extend(.PointerCursor);
	}

	> .fixed-ratio {
		.Transform(scale(1.015,1.015));
		border: 5px solid @white;
		border-bottom: none;
		border-top: 4px solid @white;
	}

	.no-item {
		border: 1px dotted @borderColor;
		background: @blackFade05;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 1px;
	}

	div[class^="col-"] {
		padding: 2px;

		.fixed-ratio {
			.RoundedShape(2px);
		}
	}
}
