@import (reference) '~rev-less/global/variables';

.form-messages.ng-active {
	margin: 8px 0;
}

.form-message {

	.has-error & {
		color: @warningColor;
	}

}

.message {
	&-warn {
		background-color: @highlight;
	}
}
