.dropdown-menu.dropdown-checked-list {
	.list-group-item {
		padding-left: 30px;
		padding-right: 30px;

		&.active:after {
			.GlyphiconFont;
			content: '\E207';
			-webkit-font-smoothing: antialiased;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			position: absolute;
			left: 8px;
			top: 9px;
			font-size: @glyphiconSizeBase;
			color: @accentColor;
		}

		.navbar-fixed-top & {
			&:extend(.dropdown-menu.dropdown-checked-list .list-group-item);
		}
	}
}