@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.root {
	display: block;
	&:global(.vbUiCheckboxDisabled) {
		cursor: not-allowed;
	}
}

.button,
.label {
	font-size: @type14;
}

.label {
	padding-left: 5px;
}

.button {
	.RoundedShape(4px);
	border: 1px solid @lightBorder;
	font-size: @type12;
	margin-right: .25rem;
	overflow: hidden;
	padding: 2px;
	position: relative;
	height: 18px;
	width: 18px;

	&:not(.checked) {
		background-color: @white;

		.icon {
			visibility: hidden;
		}
	}

	&.disabled {
		opacity: 0.5;
	}
}

.icon {
	vertical-align: top !important;
}

.mixed {
	border: 1px solid;
}
