

.form-control.selectize-style {
	.BoxShadow(0 1px 0 @blackFade05, inset 0 1px 0 fade(@white, 80%););
	padding: 8px;
	height: 36px;
	line-height: 18px;
	position: relative;
	.RoundedShape(4px);
	.VerticalGradient(#fefefe, #f2f2f2);
	background-repeat: repeat-x;
	-webkit-appearance: none;

	&:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 50%;
		right: 15px;
		margin-top: -3px;
		width: 0;
		height: 0;
		.TriangleBottom(5px, @color1LightText);
		color: @blackFade25;
		font: 14px @glyphiconFont;
		clear: left;
	}

	&.fake-disabled {
		.BoxShadow(none);
		background: none;
	}

	.glyphicons {
		top: 2px;
	}

	&[disabled],
	&.fake-disabled {
		color: @color1LightText;

		&:after {
			opacity: 0.25;
		}
	}
}

@media (min-width: 992px) {
	.form-control.selectize-style {
		height: 34px;
		line-height: 14px;
	}
}
