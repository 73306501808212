@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@smallSize: 25px;
@mediumSize: 32px;
@largeSize: 40px;
@hilightBorderWidth: 2px;

.root {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	line-height: 0;
}

.profilePicture {
	object-fit: cover;
	vertical-align: baseline;
}

.placeholderInitials {
	font-size: 10px;

	:global(.vbProfilePictureMedium) & {
		font-size: 14px;
	}

	:global(.vbProfilePictureLarge) & {
		font-size: 20px
	}
}

.wrap {
	overflow: hidden;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: @lightBorder;
	border: @hilightBorderWidth solid transparent;
	text-align: center;
	.BackgroundClip(content-box);

}

.imageSize(@smallSize);

.medium {
	.imageSize(@mediumSize);
}

.large {
	.imageSize(@largeSize);
}

:global(.vbProfilePictureMedium) {
	.medium;
}

:global(.vbProfilePictureLarge) {
	.large;
}

.imageSize(@size) {

	.wrap {
		height: @size;
		width: @size;
		.RoundedShape(@size / 2);
		line-height: @size - 2px !important;
	}

	.profilePicture {
		@innerSize: @size - 2 * @hilightBorderWidth;
		height: @innerSize;
		width: @innerSize;
		.RoundedShape(@innerSize / 2);
	}

	.profileIcon {
		right: .5px;
		top: -1px;
		font-size: @size * 0.6 !important;
		.RoundedShape(@size / 2);
	}
}
