@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';


body, html {
	font-size: 90%;
}

svg text {
	font-family: inherit !important;
}

@media(min-width: @viewPortMedWidth){
	body, html {
		font-size: 95%;
	}
}

p,
a,
li,
h1,
h2,
h3,
h4,
h5,
h6,
.btn,
.form-control,
input,
label,
th,
td  {
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: subpixel-antialiased;
}


.warning {
	color: @warningColor;
}

.tree-object .table-cell,
.tree-object .table-cell a,
table th,
table td,
table th > a,
table td a,
.table-cell-library-name span,
.tile-footer,
.help-block,
.selected-profile,
.vb-input-field,
label,
.event-message,
.event-date,
.table-cell-library-name a,
.tree-object .table-cell-library-name a,
.btn {
	font-size: @type14;
}

body,
.btn {
	line-height: @type16;
}

p {
	line-height: @type21;
}

.help-block,
.description-block
{
	color: @color1LightText;
}

.tree-object .table-cell-library-name span,
label
{
	font-weight: normal;
}

.navbar .table-cell,
.navbar .table-cell a,
.navbar .table-cell span:not(.glyphicons):not(.glyphicon):not(.loader),
.flex-container.file-list-row .table-cell,
.flex-container.file-list-row .table-cell a {
	font-size: @type14;
}
.flex-container.file-list-row .table-cell.file-list-header-col,
.flex-container.file-list-row .table-cell.file-list-header-col a,
.tile-small .video-overlay .tag,
.list-small .video-overlay .tag,
.tile-small .video-duration-overlay,
.list-small .video-duration-overlay,
.btn-sm
{
	font-size: @type12;
}

.normal-case {
	text-transform: none !important;
}

.text-align-center {
	text-align: center;
}

.text-align-right {
	text-align: right !important;
}

.text-align-left {
	text-align: left !important;
}

a {
	color: @color1;
	cursor: pointer;

	&:hover {
		color: @accentColor;
	}
}

h1, h1 a {
	font-size: @type24;
	font-weight: bold;
}
h2, h2 a, .fc-header-title h2, .report-section-header, .report-section-header a {
	font-size: @type21;
	font-weight: normal;
}

.fc-header-title h2, .report-section-header, .report-section-header a {
	font-weight: bold;
	text-transform: uppercase;
}

h3, h3 a {
	font-size: @type18;
	font-weight: normal;
}
h4, h4 a {
	font-size: @type16;
}

.carousel-mobile-list .title a {
	font-size: @type16;
	font-weight: normal;
}

h5, h5 a {
	font-size: @type14;
}
h6, h6 a {
	font-size: @type12;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small
{
	color: @color1LightText;
}

/*  table heads */

.table-list .file-list-header > div,
.admin .admin-table thead th,
.admin .admin-table thead th:hover,
.container-head > div,
.container-head:hover > div.admin,
.file-list .file-list-header .file-list-header-col,
.table > thead:first-child > tr:first-child > th,
&.admin .panel-body.no-padding .table-bordered > thead tr:last-child th {
	background: @backgroundLighten03;
	color: @color1LightText;
	padding: 0 15px;
	font-size: @type11;
	height: 31px;
	text-shadow: none;
	font-weight: normal;
	line-height: 16px;
	vertical-align: middle;
	.RoundedShape(0);
	text-transform: uppercase;
	border-top: 1px solid @white;
	border-bottom: 1px solid fade(@black, 7.5%);
}

@media(min-width: 768px) {

	h1, h1 a {
		font-size: @type30;

		small a {
			font-size: @type30 * .65;
		}
	}

	h2, h2 a .fc-header-title h2, .report-section-header, .report-section-header a {
		font-size: @type24;

		small a {
			font-size: @type24 * .65;
		}
	}

	h3, h3 a {
		font-size: @type21;

		small a {
			font-size: @type21 * .65;
		}
	}

	h4, h4 a {
		 font-size: @type18;

		small a {
			font-size: @type18 * .65;
		}
	 }

	.carousel-mobile-list .title > a { // calendar.html
		font-size: @type18;
	}

	h5, h5 a {
		font-size: @type16;

		small a {
			font-size: @type16 * .65;
		}
	}
	h6, h6 a {
		font-size: @type14;

		small a {
			font-size: @type14 * .65;
		}
	}
}

/*================================= Headings ================================*/
/* These can be used on any type of element that needs to act like a header
/* even if it is not a header element.                                       */
/*===========================================================================*/

.header {
	&-type21 { // video-source-schedule.html
		font-size: @type21;
		font-weight: bold;
	}
}
