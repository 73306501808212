@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.tooltip {
	background-color: @white;
	.RoundedShape(50%);
	.BoxShadow();
	border: 1px solid @white;
	color: #898989;
	cursor: pointer;
	font-size: @type12;
}

.contentWrapper {
	padding: .25rem 0;
	text-align: left;
}

.contentLink {
	color: @white;
	text-decoration: underline;
}
