@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.TextInput {
	border: 1px solid @lightBorder;
	.RoundedShape();
	color: @primaryDark;
	font-size: @type14;
	height: 40px;
	line-height: 1.33;
	padding: 10px 16px;
	width: 100%;

	.Placeholder({
		color: @color1Lighten50;
	});

	&:active {
		color: @primaryDark;
	}

	&::-ms-clear {
		display: none;
	}

	&:global(.ng-invalid):not(:global(.ng-untouched)):not(:focus) {
		border: @invalidFieldBorder;
	}
}

.TextInputFocusBorder:focus {
	border-width: 3px;
	.BoxShadow(inset 0px 0px 5px 0px @blackFade25, 0 0 12px @blackFade25;); // outer for visibility on an accent background
	outline: 0;
}