.root {
	display: block;
	padding: 1rem;
}

.list {
	list-style: none;
	padding: 0;
	margin: 1rem 0;
}

.noResults {
	margin-top: 1rem;
	text-align: center;
}