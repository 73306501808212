@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.borderRight {
	border-right: 1px solid @borderColorLight;
	padding-right: 8px;
}

.footerRow {
	padding: 8px 15px 0 15px;

	span {
		margin: 4px;
	}
}

.dtmfDetailsHeader:hover {
	cursor: pointer;
}

.zoomPassword {
	border-right: 1px solid @borderColorLight;
	padding-right: 8px;
}
