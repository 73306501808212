@import (reference) '~rev-less/global/variables';

.errorMsg {
	color: @highlightLighten35;
	font-size: @type21;
}

.errorMsgReason {
	color: @white;
	font-size: @type14;
}

.msTeamsConnecting {
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	max-width: 220px;
}

.timer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-wrap: nowrap;
}