@import "../global/variables";
@import "../global/mixins";

.typeahead.dropdown-menu {
	margin-top: 9px;
	background: #e5f5fd; /* accent at 10% */
	border: 1px solid rgba(102, 175, 233, 0.8);
	.BoxShadow(0 0 4px rgba(102, 175, 233, 0.8));

	&:before {
		content: '';
		.TriangleTop(11px, rgba(102, 175, 233, 0.8));
		position: absolute;
		left: 13px;
		top: -11px
	}

	&:after {
		content: '';
		.TriangleTop(9px, #e5f5fd);
		position: absolute;
		left: 15px;
		top: -9px
	}

	> li > a {
		color: #444;
		text-align: left;

		.glyphicons {
			margin-right: 10px;
			color: fade(@black, 35%);
		}
	}


	> .active > a {
		color: @white;
	}
}