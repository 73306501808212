@import "../global/variables";
@import "../global/mixins";

.progress-wrapper {
	.progress {
		height: 20px;
		background: rgba(204, 215, 220, 1);
		margin-bottom: 0;

		.progress-bar {
			background: @accentColor;

			span.pull-right { //percetage text
				max-width: ~'calc(100% - 4px)'; //ensures that small values are readable
				margin-right: 2px;

				&:before { //some padding to the left
					content: '\00a0\00a0';
				}
			}
		}
	}

	.glyphicons {
		float: right;
		position: relative;
		right: -20px;
		top: -10px;
		color: @color1LightText;
		line-height: 0;
	}

	&.mini {
		.progress {
			height: 5px;
		}

		.glyphicons {
			top: -3px;
		}
	}

	&.with-cancel {
		padding-right: 20px;

		.glyphicons.circle_remove{
			right: 0 !important;
		}
	}

	.circle_remove {
		.PointerCursor;
	}
}