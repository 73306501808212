@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.optionRow {
	display: flex;
	border-bottom: 1px solid @blackFade05;
	height: 45px;
	justify-content: space-evenly;
	align-items: stretch;
	cursor: pointer;
	position: relative;

	&:hover {
		background: @blackFade05;
	}


	:global(.assigned) &,
	:global(.assigned) &:hover {
		color: @accentColor;

		span.subDisplay {
			color: @white;
		}
	}

}

.cell {
	line-height: 36px;

	&:first-child {
		margin-left: 16px;
	}

	margin-right: 10px;

	:global(.assigned) & :global(.btn-link) {
		color: @accentColor;
	}
}

.descriptionCell {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;

	>span {
		line-height: 15px;
		font-size: 15px;
		color: @primaryDark;

		:global(.assigned) & {
			color: @accentColor;
		}

		&.subDisplay {
			line-height: 14px;
			font-size: 11px;
			text-transform: capitalize;
			color: #909090 !important;
		}
	}

}

.btnBadge {
	height: 18px;
	margin-top: 11px;
	margin-right: 8px;
	padding: 0 8px !important;
	border: 1px solid #909090;
	border-radius: 16px !important;

	font-size: .75rem;
	outline: none !important;
	box-shadow: none;
	text-decoration: none;

	&:focus {
		box-shadow: 0 0 2px black;
		border: 1px solid black;
	}
}

.badgePopup {
	border: 1px solid @lightBorder;
	position: relative;
	transform: translate(-42px, 0);
	background-color: @color1Lighten50;
	.RoundedShape(5px);
	font-size: @type14;

	&:hover {
		font-size: @type14  !important;
	}

	&:before {
		content: " ";
		position: absolute;
		border-style: solid;
		border-width: 0 8px 8px 8px;
		border-color: transparent transparent #b0b0b0 transparent;
		right: 10%;
		margin-left: -14px;
		top: -8px;
		z-index: 200;
	}

	ul {
		list-style: none;
		padding: 10px;
		margin-bottom: 0 !important;
		li {
			margin-bottom: 10px;
		}

		:global(.dropdown-item) {
			.RoundedShape(5px);
			background-color: @white;
			color: @color1Lighten50;
			line-height: 1.1;
			padding: 5px;
			border: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.teamIcon {
	composes: user from global;

	top: -3px;
	vertical-align: middle;
	height: 30px;
	width: 30px;
	margin-top: 0;
	line-height: 28px;

	&:before {
		left: 8px;
		position: absolute;
		top: -1px;
		font-size: 12px;
	}

	&:after {
		// cogwheel
		content: "\E137";
		display: inline-block;
		.GlyphiconFont;
		-webkit-font-smoothing: antialiased;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		position: absolute;
		top: 8px;
		font-size: 6.5px;
		right: 5.5px;
		top: 8px;
	}
}
