@import (reference) "~rev-less/global/variables";

.list {
	list-style: none;
	padding-left: 1.75rem;

	&.listWithExpander {
		padding-left: 2.75rem;
	}
}

.listItem {
	margin-bottom: .25rem;
}

.checkbox {
	display: block !important;
}

.groupCheckbox {
	composes: checkbox;
	margin-bottom: .5rem;
}

.expanderBtn {
	composes: vbBtnTransparent from '~rev-shared/ui/buttons/vb-btn.module.less';
	padding-left: 0;
	vertical-align: top;
}

.expanderIconExpanded {
	transform: rotate(90deg);
	transform-origin: center;
}

.highlight {
	background-color: @highlight;
	color: black;
}