@import (reference) "~rev-less/global/mixins";
@import (reference) "~rev-less/global/variables";

@mobileMaxWidth: @viewPortMedWidth - 1px;

.root {
	min-height: 1px; // for IE / Edge
	max-width: 100%;

	:global([slot='mainContent']) {
		display: block;
		height: 100%;
	}
}

.mainContentSlot {
	position: relative;
	flex: 1 1;
	order: 1;

	@media (max-width: @mobileMaxWidth) {
		> :global([slot='mainContent']) {
			.AbsoluteFill;
			overflow: hidden;
		}
	}
}

.sidebarSlot {
	order: 2;
}

.mainContentSidebarOpen {
	@media (max-width: @mobileMaxWidth) {
		.flexItem(none);
	}
}
