@darkBlue: rgb(0,96,144);
@color1: #303030;
@color1Fade50: fade(@color1, 50%);
@color1LightText: lighten(@color1, 35%);

@black: #000;
@blackFade50: fade(@black, 50%);
@blackFade35: fade(@black, 35%);
@blackFade10: fade(@black, 10%);
@blackFade05: fade(@black, 5%);

@accentColor: #108dda;
@accentFade20: fade(@accentColor, 20%);
@accentFade10: fade(@accentColor, 10%);

@white: #fff;

.TriangleTop(@size: 8px, @color: @white) {
	border-style: solid;
	border-width: 0 @size @size @size;
	border-color: transparent transparent @color transparent;
}

.vb-insight{
	padding: 0;
	border: 1px solid rgba(0, 0, 0, 0.1);
	background: #fafafa;
	height: 436px;

	width: 100%;
	position: relative;
	height: 436px;


	&:after {
		visibility: hidden;
		display: block;
		content: "";
		clear: both;
		height: 0;
	}

	.search-field-wrapper {
		position: relative;
		padding: 16px;
		border-bottom: 1px solid @blackFade10;

		.search-field-inner {
			display: flex;
			flex-flow: row nowrap;
			align-items: stretch;
			justify-content: space-between;

			> div {
				display: flex;
				flex: 1 1 auto;
				> .btn-group {
					padding-left: 15px;
				}
			}
		}

		.search-field {
			position: relative;
			top: 0;
			height: 48px;
			background: @white;
			z-index: 1;
			width: 100%;

			border: 1px solid rgba(0, 0, 0, 0.25);
			border-radius: 4px;
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);

		}

		&.focus .search-field {
			border: 1px solid @blackFade35;
			.icon {
				color: @accentColor;
			}
		}

		.icon {
			position: absolute;
			left: 12px;
			top: 12px;
			color: rgba(0, 0, 0, 0.25);
			font-family: 'Glyphicons Regular';
			font-style: normal;
			font-size: 24px;
		}

		input {
			background: none;
			width: 100%;
			padding: 0 16px;
			line-height: 36px;
			height: 48px;
			text-align: center;
			color: @darkBlue;
			border:1px solid transparent;

			&:focus {
				outline: none;
			}
		}



		.btn-close {
			position: absolute;
			top: 4px;
			right: 4px;
			height: 38px;
			z-index: 100;
			background: @blackFade35;
			color: @blackFade50;
			border: 1px solid @blackFade10;

			color: @white;
		}

	}

	.insight-options {
		position: absolute;
		left: 16px;
		right: 16px;
		z-index: 21;
		height: 375px;

		&:before {
			content: '';
			position: absolute;
			left: 10px;
			top: -11px;
			.TriangleTop(@size:14px, @color: @blackFade35);
		}

		&:after {
			content: '';
			position: absolute;
			left: 12px;
			top: -9px;
			.TriangleTop(@size:12px, @color: @white);
		}

		.insight-scroll-container {
			position: relative;
			right: 0;
			left: 0;
			top: 0;
			height: 349px;
			margin-top: 2px;
			padding-top: 1px;
			border: 1px solid @blackFade35;
			background: #fcfcfc;
			box-shadow: 0 0 10px @blackFade50;
			transform: translate3d(0,0,0);

			.btn-close {
				position: absolute;
				top: 5px;
				right: 5px;
				z-index: 100;
			}
		}

		cdk-virtual-scroll-viewport {
			height: 346px;
		}

		.status-message {
			margin: 20px;
		}
	}

	.insight-option-row {
		display: flex;
		flex-flow: row nowrap;
		align-items: stretch;
		justify-content: space-between;

		>div {
			flex: 0 0 auto;
			border-bottom: 1px solid rgba(0, 0, 0, 0.05);
			padding: 0;
			cursor: pointer;
			position: relative;
			height: 45px;
			line-height: 36px;
			&.flex-fill {
				flex: 1 1 auto;
			}
		}

		.insight-option {
			display: flex;
			flex-direction: column;
			justify-content: center;

			>span {
				line-height: 15px;
				font-size: 15px;

				&.insight-sub-display {
					line-height: 14px;
					font-size: 11px;
					text-transform: capitalize;
					color: #909090;
				}
			}
		}

		.plus {
			color: @color1Fade50;
		}

		.circle {
			height:30px;
			width: 30px;

			background: @blackFade10;
			display: inline-block;
			vertical-align: middle;
			margin-right: 8px;
			text-align: center;
			line-height: 28px;
			color: @color1Fade50;
			margin-top: 4px;
			border-radius: 9999px;
		}

		&:active {
			box-shadow: inset 0 0 4px @blackFade10;
		}

		&:hover {
			background: @accentFade10;
			background: @blackFade05;

			&:focus,
			&:active,
			&:focus:active {
				background: @accentFade20;
				background: @blackFade10;
			}
		}

		&.assigned,
		&.assigned :hover
		{
			color: @accentColor;

			.btn-assigned {
				color: @accentColor;
			}

			span.insight-sub-display
			{
				color: @white;
			}

			.circle {
				background: @accentFade10;
				color: @accentColor;
			}
		}

	}

	.ui-match {
		background: hsla(60, 100%, 50%, 1) !important;
		color: hsla(0, 0%, 8%, 1) !important;
	}


	.widget-members {
		&.focus  {
			.insight-scroll-container {
				>div.selected {
					background: @blackFade35;
					background: none;
				}
			}
		}

		.insight-option-row > div:first-child
		{
			padding-left: 16px;
		}

		.insight-scroll-container {
			.no-items {
				padding: 16px;
				color: @color1LightText;
			}
		}

		cdk-virtual-scroll-viewport {
			height: 354px;
		}
	}


}
