@import "~rev-less/global/variables";

.zoom-import {

	.modal-lg {
		@media(min-width: 1200px) {
			width: 80%;
		}
	}

	.modal-body {
		min-height: 158px;
		padding: 0;
	}

	.modal-footer {
		margin-top: 0;
	}

	.system-msg {
		font-size: @type16;
		margin-bottom: 0;
		line-height: @type21;
		padding: 16px;

		.glyphicons {
			margin-right: 5px;
			top: 2px;
		}
	}
}

.import-zoom-grid-list {
	> ul {
		height: 60vh;

		> li {

			.grid-list-cell {
				font-size: @type12;
			}

			.grid-list-cell:nth-child(2) {
				font-weight: bold;
				font-size: @type14;
			}
		}
	}
}