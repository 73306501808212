@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.btn-primary.theme-accent {
	position: relative;
	border-width: 0;

	&:hover:after {
		content: '';
		.AbsoluteFill();
		background-color: @blackFade25;
	}
}
