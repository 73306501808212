@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.vbUiBtn {
	display: inline-block;
    padding: 8px 12px;
    margin-bottom: 0;
    font-size: @type14;
    font-weight: 400;
    line-height: 1.4;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    .RoundedShape(@defaultBorderRadius);
}

.vbSecondaryBtn {
	composes: vbUiBtn;
	background-color: @borderColorLight15;
	border: 1px solid @lightBorder;
	background: linear-gradient(180deg, @white, @borderColorLight15);
	color: @primaryDark;
	.BoxShadow(@black);

	&:focus,
	&:hover {
		.BoxShadow(inset 0 2px 3px @blackFade40);
		text-decoration: none;
		color: @primaryDark;
	}

	&[disabled] {
		color: @color1Lighten50;
		&:hover {
			.BoxShadow(none);
		}
	}

	:global(.glyphicons) {
		color: inherit !important;
	}
}
