@import (reference) '~rev-less/global/variables';

.root,
.formGroupWapper {
	display: block;
}

.label{
	display: inline-block;
}

.validation {
	margin-top: 10px;
}

.controlRequired {
	position: relative;

	&:after {
		content: '\002A';
		color: @warningColor;
		position: absolute;
		top: 0;
		right: -17px;
		display: inline-block;
		font-size: @type21;
		font-weight: 400;
		vertical-align: middle;
		text-transform: none;
		text-shadow: @iconBorder;
	}
}

.glyphiconsWrapper {
	position: absolute;
	top: 3px;
	right: 3px !important;
}
