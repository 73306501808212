@import "global/variables";
@import "global/mixins";


.form-control {

	&:focus:not(:active):not(.active)
	{
		color: @color1;
		&:extend(.focusedInput);
	}

}

/*
	this needs to be worked out more before activating

	.btn {

	&:focus:not(:active):not(.active)
	{
		&:extend(.focusedItem);
	}
}*/

// override bootstrap. Note: this is needed because our build is out of date.
.btn-group > .btn:focus, .btn-group-vertical > .btn:focus {
	&:extend(.focusedItem);
}

// override bootstrap
input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
	&:extend(.focusedItem);
}