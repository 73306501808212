@import (reference) '~rev-less/global/variables';

.box {
	&-brand_truncate {
		max-height: @type2em;
		overflow: hidden;
		padding-right: @type1em;
		position: relative;
	}
}
