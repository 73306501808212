@import "../global/variables";
@import "../global/mixins";

.rating {
	&:focus,
	& > span {
		outline: none;
	}

	.rating-readonly > span:focus {
		outline: none;
	}
}

.star {
	.rating &,
	.rating-readonly & {
		color: @blackFade25;

		&.active {
			color: @accentColor
		}
	}
}

.rating:hover {
	&:extend(.PointerCursor);
}