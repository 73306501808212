@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@selectBtnWidth: 40px;
@focusErrorBorderPadding: 6px @selectBtnWidth 8px 8px;

.root {
	ng-select {
		&:global(.custom) {
			cursor: pointer;

			&:global(.ng-select-opened) {
				:global(.ng-select-container) {
					border-width: 3px;
					.BoxShadow(inset 0px 0px 5px 0px @blackFade25);
					outline: 0;
					border-right: 0;
					border-color: var(--theme-accent);
				}
			}

			&:global(.ng-invalid):not(:global(.ng-untouched)) {
				border: 3px solid @warningColor;
			}

			:global(.ng-select-container)  {
				.RoundedShape();
				background: @white;
				border: 1px solid @lightBorder;
				display: block;
				position: relative;
				height: 40px;
				overflow: hidden;

				:global(.ng-value-container) {
					background: @white;
					.BoxSizing(border-box);
					border: 1px solid transparent;
					color: @primaryDark;
					cursor: pointer;
					font-size: @type14;
					line-height: 1.6;
					margin: 0;
					outline: none;
					padding: 6px 10px;
					width: 100%;
					height: 100%;
					overflow: hidden;
					.Appearance(none);

					:global(.ng-input) {
						padding-left: 10px;

						input {
							cursor: inherit;
						}
					}
				}

				&:after,
				&:before {
					content: " ";
					pointer-events: none;
					position: absolute;
					z-index: 2;
				}

				&:after {
					border-top: 10px solid var(--theme-accent-txt);
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					display: block;
					height: 0;
					margin-top: -3px;
					right: 1em;
					top: 50%;
					width: 0;
				}

				&:before {
					background: var(--theme-accent);
					height: 100%;
					right: 0;
					top: 0;
					width: @selectBtnWidth;
				}
				select:disabled {
					pointer-events: none;
				}
				&:global(.ng-has-value) {
					:global(.ng-placeholder) {
						display: none;
					}
				}
			}
			:global(.ng-dropdown-panel) {
				background: @white;
				color: @black;
				border: 1px solid;
				margin-top: 3px;

				:global(.ng-option) {
					padding: 8px 10px;
					border-bottom: 1px solid @color1Lighten65;

					&:hover {
						background-color: @color2Lighten04;
					}

					&:global(.ng-option-selected), &:global(.ng-option-marked) {
						background-color: var(--theme-accent);
						color: var(--theme-accent-txt);
					}
				}
			}
		}
	}

}
