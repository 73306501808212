@import (reference) '~rev-less/global/variables';

.buttonContainer {
	display: flex;
	align-items: center;
	flex-direction: row;
	min-height: 48px;
	padding-top: .5rem;
	padding-bottom: .5rem;
}

.containerFluid {
	padding-right: 16px;
	padding-left: 16px;

	@media (min-width: @viewPortLargeWidth) {
		padding-left: 32px;
		padding-right: 32px;
	}
}
