@import (reference) '~rev-less/global/variables';

@backgroundColor: @warningColor;
@backgroundHoverColor: @warningColorFade75;
@textColor: @white;

.vbUiBtnDanger {
	composes: vbUiBtn from './vb-btn-secondary.module.less';
	background-color: @backgroundColor;
	border-color: @backgroundColor;
	color: @textColor;
	cursor: pointer;
	position: relative;
	border-width: 1px;
	border-style: solid;

	&:hover:not([disabled]),
	&:focus {
		background: @backgroundHoverColor;
		color: @textColor;
		text-decoration: none;
	}

	&[disabled] {
		opacity: .5;
	}

	:global(.glyphicons) {
		color: inherit !important;
	}
}