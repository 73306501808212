
.toggleContainer {
	display: flex;
	margin-top: 8px;

	div {
		display: flex;
		flex-direction: row;

		:global(.vb-icon) {
			color: white !important;
		}
	}
}
