@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.wrapper {
	tags {
		background-color: @white;
		border-style: solid;
		border-width: 1px;
		.RoundedShape(@defaultBorderRadius);
		padding-left: 8px;
		padding-right: 24px;
		.BoxShadow(none);
		width: 100%;

		tag {
			word-break: break-word;
		}

		&:global(:not(.tagify--focus)) {
			border-color: @lightBorder;
		}

		&:global(.tagify--focus) {
			border-width: 3px;
			.BoxShadow(inset 0 0 5px 0 @blackFade25);
		}

		:global(.tagify__input) {
			width: 100%;
		}
	}

	.tagsIconPlus {
		.RoundedShape(10px);
		font-size: 14px;
		pointer-events: none;
		position: absolute;
		right: 5px;
		top: 6px;
	}

	:global(.tagify__input) {
		color: @color1 !important;
	}
}

.dropdown {
	transform: translateY(2px) !important;
	:global(.tagify__dropdown__wrapper) {
		border-color: @lightBorder;
	}
}

.tag {
	.pathItem;
}

.dropdownItem {
	.pathItem;
	color: @color1;
}

.pathItem {
	font-size: 13px;

	> span,
	> small {
		display: block !important;
		padding-right: .5rem;
	}

	> span {
		font-weight: bold;
		line-height: 1.4;
		margin-bottom: 2px;
	}

	> small {
		width: 100%;
		line-height: 12px;
	}
}
