@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

/*  table view is built using nestable css table-cell rows

	• use table-cell-fill for the main cell, which will in expand in width

*/
.file-list {
	margin-bottom: 0;
	display: table;
	list-style-type: none;
	padding-left: 0;
	width: 100%;
	background: @white;

	& & {
		padding-left: 0;
		list-style-type: none;
		width: 100%;
		margin-left: 90px;
		display: table;
		table-layout: fixed;
	}

	.disabled {
		cursor: not-allowed !important;
	}

	&-wrap {
		overflow: hidden;
		.RoundedShape(3px);
		position: relative;
		.BoxShadow(0 0 2px @blackFade25);
	}

	&-row {
		position: relative;
		vertical-align: middle;
		clear: both;
		display: table-row;
		background: @white;

		.list-row-select & {
			cursor: pointer;
		}

		.file-list.draggable & {
			.CursorGrab();

			&.ui-sortable-helper {
				.CursorGrabbing();
				.BoxShadow(0 2px 4px fade(@black, 20%));
				background: @white;
				opacity: 0.75;
			}
		}

		&:hover {
			background: fade(@accentColor, 5%);

			li:hover {
				background: none;
			}
		}

		&.not-selectable,
		&.not-selectable:hover {
			cursor: default;
			background: none;
		}

		&.selected, .selected{
			background: @white;
			border-color: @accentFade20;
			.hide-if-selected {
				display: none;
			}

			> div {
				background: fade(@accentColor, 15%);
			}

			li.selected,
			li:hover {
				background: none;
			}

			+ li {
				border-color: @accentFade20;
			}
		}

		div.show-on-hover {
		   display: none;
		}

		&:hover {
			div.hide-on-hover {
			   display: none;
			}

			div.show-on-hover {
			   display: table-cell;
			}
		}

		div.show-on-error {
		   display: none;
		}

		&.error > div {
			&:first-child {
			   background: fade(@warningColor, 85%);
			   color: @white;
			}

			&.show-on-error {
			   display: block;
			}
		}

		&.inactive {
			background: fade(@black, 2.5%);
			color: @color1LightText;

			.table-cell,
			.table-cell a {
				color: @color1LightText;
			}

			.image-wrapper img {
				opacity: 0.5;
			}
		}

		&.processing-error {
			background: #f9f0f0;

			.table-cell,
			.table-cell a {
				color: fade(@warningColor, 75%);
			}

			.image-wrapper {
				img {
					opacity: 0.5;
				}
			}
		}

		&.not-ready {
			background: fade(@black, 2.5%);
		}

		.table-cell {
			text-align: right;
			color: @color1MedText;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			vertical-align: middle;
			border-top: 1px solid fade(@black, 7.5%);
			padding: 0 8px;
			line-height: 40px;


			&.thumbnail-cell {
				padding: 0 8px 0 4px;
			}

			.flex-container& {
				text-align: left;
			}

			&.primary-cell {
				&,
				a {
					font-weight: bold;
					text-align: left !important;
				}

				&:not(.file-list-header-col) {
					&,
					a {
						color: @color1;

						.uploading&,
						.not-ready&,
						.inactive& {
							color: @color1LightText;
						}
					}
				}

				.primary-cell-link {
					max-width: 600px; /* needed because sidebar gets pushed out of viewport in list view - AV-14828 */

					@media all and (max-width: 1220px) {
						max-width: 400px;
					}
				}

			}

			&.file-list-header-col {
				border: none;

				&.primary-cell {
					font-weight: normal;
				}


			}

			&.text-align-center {
				text-align: center;
			}

			&.table-cell-fill {
				/*  not intuitively, max-width controls ellipsis */
				max-width: 100px;
			}

			&:first-child {
				padding-left: 16px;
			}
			&:last-child {
				padding-right: 16px;
			}
		}
	}

	div.error-dismissable {
	   width: 62px;
	   height: 44px;
	   background: fade(@warningColor, 90%);
	   color: @white;
	   position: relative;
	   left: 20px;
	   z-index: 1;

	  &:before {
		   content: '';
		   width: 62px;
		   position: absolute;
		   right: 100%;
		   width: 0;
		   height: 0;
		   border-top: 22px solid transparent;
		   border-right: 20px solid fade(@warningColor, 90%);
		   border-bottom: 22px solid transparent;
		}
	}

	li > div:first-child,
	&-header-col:first-child {
		padding-left: 15px;
		text-align: left;
	}

	div&-header-col.table-cell-order {
		height: auto;
	}

	.file-list-header {
		&,
		&:hover {
			background: #eee;
			background: @backgroundLighten03;
			overflow: hidden;
			.BorderTopRightRadius(3px);
			.BorderTopLeftRadius(3px);
			width: 100%;
			cursor: default;
		}

		a {
			&:extend(.PointerCursor);
		}
	}

	&-header-col {
		text-align: right;
		color: @color1LightText;
		font-size: 10px;
		text-transform: uppercase;
		line-height: 1;
		border: none;
		display: table-cell;

		> a {
			color: @color1LightText;

			&.active {
				color: @color1;
				font-weight: bold;
			}

			> .caret {
				display: none;
			}

			&.sort-order {
				.caret {
					.TriangleTop(6px, @color1);
				}

				&.up {
					.caret {
						.TriangleBottom(6px, @color1LightText);
					}

					&.active .caret {
						border-color: @color1 transparent transparent transparent;
					}
				}

			}

			&.sort-order,
			&.active {
				> .caret {
					display: inline-block;
				}
			}
		}
	}

	.icon {
		width: 55px;
		height: 35px;
		display: inline-block;
		margin-right: 10px;
		vertical-align: middle;
	}

	div.file-name {
		text-align: left;
		position: relative;
	}

	.button-tools {
		text-align: right;
		overflow: visible !important;

		.dropdown-menu {
			right: 0;
			left: auto;
			text-align: left;
			margin: 3px 0 0 0;
			padding: 0;

			.list-group {
				margin: 0;

				.list-group-item {
					line-height: 14px;
					border: none;
					margin: 0;

					&:hover {
						background: @accentColor;
						color: @white;
					}

					&.to-detail-view {
						position: relative;

						.chevron-right {
							position: absolute;
							right: .25em;
							line-height: 2em;
						}
					}
				}

				.divider {
					padding: 0;
					margin: 0;
				}
			}

			&:before {
				content: '';
				width: 0;
				height: 0;
				.TriangleTop(10px, @borderColor);
				position: absolute;
				right: 8px;
				top: -9px;
			}

			&:after {
				content: '';
				width: 0;
				height: 0;
				.TriangleTop(8px, @white);
				position: absolute;
				right: 10px;
				top: -7px;
			}

			&.dropdown-menu-with-icons {
				.list-group-item {
					padding-left: 36px;
					position: relative;

					.list-group-item-icon {
						position: absolute;
						left: 10px;

						.loader {
							margin: 0;
						}
					}
				}
			}
		}


		.dropdown.open .dropdown-menu,
		.dropdown.open .dropdown-menu .list-group-item:first-child {
			.BorderTopLeftRadius(3px);
		}

		.dropup .dropdown-menu {
			&:before {
				.TriangleBottom(10px, @borderColor);
				top: 99%;
			}

			&:after {
				.TriangleBottom(10px, @white);
				top: 99%;
			}
		}
	}
}

div.table-cell {
	&-order,
	&-buttons {
		min-width: 42px !important;
		width: 42px;
		max-width: 42px !important;
		border-right: 1px solid @blackFade05;
		text-align: center !important;
		font-weight: bold;
		padding: 0 !important;

		button.btn-checkbox {
			margin: 8px auto;
			color: @white;
			&:hover {
				color: @color1MedText;
			}
		}
	}

	&-order {
		height: 43px;

		.active .caret {
			display: inline-block;
		}
	}
}

.file-icon {
	margin-right: 10px;
	.flexShrink(0);
	vertical-align: middle;
	display: inline-block;

	.lock {
		color: @black;
		font-size: @type12;
		left: 3px;
		position: absolute;
		top: 9px;
		z-index: 1;
	}

	.volume_up {
		color: @black;
		font-size: @type12;
		left: 41px;
		position: absolute;
		top: 9px;
		z-index: 1;
	}

	.fixed-ratio-wrapper {
		line-height: normal;
	}

	> .file-folder-icon,
	> .image-wrapper {
		width: 55px;
		display: block;
		vertical-align: middle;
		height: 36px;
		line-height: normal;
	}

	> .image-wrapper {
		// overflow: hidden;
		position: relative;

		img {
			width: 55px;
			height: 34px;
			position: relative;
		}
	}

	> .file-folder-icon {
		fill: fade(@black, 20%);
		margin-top: -2px;
	}

	&.file-icon-library-hack {
		padding-top: 3px !important;
	}
}

.file-list {
	display: block;

	.table-cell {
		&:extend(.flexContainerStatic);
		.alignItems(center);

		> span:not(.glyphicons),
		> a {
			&:extend(.TextClip);
		}

		&.primary-cell a {
			text-align: left;
		}

		&.file-list-header-col {
			line-height: 28px;
		}
	}

	.bulk-actions .table-cell.file-list-header-col {
		line-height: 36px;
		padding: 0;
		border-top-color: @white;
		border-bottom: 1px solid fade(@black, 7.5%);
	}

	.file-list-header,
	.file-list-header:hover {
		padding: 0;
	}

}

.table-cell > .btn-toolbar {
	margin-left: 0;
}

.btn-toolbar.flex-container {
	.flexGrow(1);
	.flexFlow(row, nowrap);

	.btn,
	.btn-spacer {
		&:extend(.flexContainerStatic);

		.flexBasis(32%);
		margin-right: 1%;
	}
}

.file-list-row{
	.show-on-hover {
		display: none;
	}

	&:hover, &.selected, & .selected{
		.show-on-hover {
			display: block;
		}
	}
}

.file-icon,
.file-list .icon {
	margin-top: 0;
	position: relative;
}

.table-cell-order {
	.flexJustifyContent(center);
}

.flex-align-right {
	.flexJustifyContent(flex-end);
}

.file-list .bulk-actions .table-cell.file-list-header-col {
	line-height: 28px;
	padding: 6px !important;
}

.panel-devices .btn-toolbar.flex-container {
	.btn,
	.btn-spacer {
		display: table-cell;
		width: 24% !important;
		margin-right: 1%;

		@-moz-document url-prefix() {
			display: inline-block;
			width: 24% !important;
			margin-right: 1%;
		}
	}
}

.status-icons,
.status-icon-header,
.file-icon {
	font-size: @type16;
	text-align: center !important;
	padding: 0 !important;
}

.status-icons .video-processing {
	height: 16px;
	position: relative;
	text-align: center;
}

svg {
	-webkit-touch-callout: none;
	.UserSelect(none);
	display: block;
	width: 100%;
	height: 100%;
}

.btn-select-all {
	padding: 4px 6px;
	width: 26px;
}

.file-list  div.file-name a,
a.file-name {
	&:extend(.TextClip);
	font-weight: bold;
}

@media(min-width: 992px) {
	.btn-toolbar.flex-container {
		.btn {
			.flexBasis(32%);
			flex-basis: 32%;
			margin-right: 1%;
		}
	}
}
