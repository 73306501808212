@import (reference) "~rev-less/global/variables";

@statusHeight: 50px;

.root {
	position: relative;

	&:global(.infiniteGridEnableStatusBar) {
		margin-bottom: @statusHeight;
	}
}


.scrollExpiredMsg {
	position: absolute;
	width: 100%;
	bottom: -@statusHeight;
	left: 0;
	height: @statusHeight;
	text-align: center;
	padding-top: 20px;

}

.themedLink {
	a {
		color: var(--theme-accent);
	}
}

.noRecords {
	position: absolute;
	width: 100%;
	top: 30%;
	bottom: 0;
	text-align: center;
	left: 0;
	z-index: 1;
}
