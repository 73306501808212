.table-list { // manage-roles.html
	list-style-type: none;
	padding-left: 0;
	width: 100%;
	display: table;

	li {
		position: relative;
		vertical-align: middle;
		clear: both;
		display: table-row;

		> .table-cell:first-child {
			border-left: none;
		}

		> .file-list-header-col {
			padding: 8px 15px;
		}

		> div {
			&:extend(.WordBreak);
			padding: 12px 15px;
			text-align: left;
			color: @color1MedText;
			vertical-align: top;
			border-top: 1px solid @blackFade05;
			border-left: 1px solid @blackFade05;
			display: table-cell;
			width: 33%;
			word-wrap: break-word;
			font-size: 13px;
		}
	}

	&.table-fixed {
		table-layout: fixed;
	}

	.file-list-header > div {
		background: #596065;
		color: fade(@white, 70%);
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

/* break it down for mobile  */
@media(max-width: 600px) {
	.table-list.role-table role li {
		display: block;

		&.file-list-header {
			display: none;
		}

		> div {
			display: block;
			width: 100%;

			&:first-child {
				background: rgba(89,96,101,0.2);
				padding-top: 10px;
				padding-bottom: 10px;
				font-weight: bold;
			}
		}
	}
}