@import (reference) "~rev-less/global/mixins";
@import "~rev-less/global/variables";
/* Main nav dropdown  */

/*

	this is messy: .notification-cell is the main nav section and dropdown
				   .notification-alert is for the list page and the drop downs
				   .high-alert is for the not implemented async notifications
*/
.notification-list,
.notification-cell {
	.media-list {
		margin: 0 2px;

		.no-notifications-msg {

			padding: @typeUnit4x @typeUnit1x;
			background: @color2Lighten05;
			text-align: center;
			.PointerCursor;

			h4 {
				color: @color1LightText;
				font-size: @type21;
				margin-bottom: 1rem;
				font-weight: normal;
			}

			a {
				margin-top: 8px;
			}
		}
	}

	h4,
	a,
	p {
		color: @color1LightText;
	}

	h4 {
		color: @color1;
		font-weight: bold;
		font-size: 14px;
		margin: 0;
		word-wrap: break-word;
	}

	li.media {
		margin: 1px @typeUnit1x;
		position: relative;
		overflow: visible;
		white-space: normal;

		&:first-child {
			margin-top: 15px;
		}
		&:last-child {
			margin-bottom: 15px;
		}
		.clickable {
			&:extend(.PointerCursor);
		}

		&:hover .clickable {
			background: @accentColor05;
		}

		&.high-alert {
			background: @white;

			h4,
			.notification-tab
			{
				color: @warningColor;
			}
		}

		.media-body-fill {
			margin-left: 32px;
		}
	}

	.notification-msg {
		margin: 0 0 5px 0;
		word-wrap: break-word;
	}

	.notification-time-stamp {
		margin: 0;
	}

	[ui-sref] {
		cursor: pointer;

		&:hover h4 {
			text-decoration: underline;
		}
	}
}

/* Notifications page  */
.notification-list {

	padding-top: 48px;

	ul {
		padding: 0 15px;
		list-style-type: none;
	}

	h2 {
		margin: 15px 0 30px 0;
	}

	h4 {
		font-weight: bold;
		font-size: @type16;
		margin: 0;
	}

	li.media, li.media:first-child {
		margin: 0;
	}
}

.notification-section {
	padding-bottom: 20px;

	h3 {
		font-size: 24px;
		color: @color1MedText;
	}
}
