@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.imagePreview {
	background: @adminPanel;
	border: 1px solid @borderColorLight;
	margin: @typeUnit1x 0;
	padding: @typeUnit1-2x;
	.RoundedShape();

	button {
		float: right;
		top: 5px;
		clear: both;
	}

	> img {
		max-height: 50px;
	}
}

.requiredCt {
	min-width: 150px;
}

.requiredIndicator:after {
	right: 1.2rem !important;
}

