@import (reference) '~rev-less/global/variables';

.spinner {
	height: 150px;
	position: relative;
	margin-bottom: 1rem;;
	width: 100%;
}

.timer {
	font-size: @type48;
	line-height: 1.25;
}

@media (max-width: @viewPortMobileDeviceWidth) {
	.timer {
		font-size: @type36;
		line-height: 1.75;
	}

	.stopBtn {
		margin-bottom: 1rem;
	}
}