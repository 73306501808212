@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

recording-menu {
	.recording-menu-component {
		&-form {
			.form-control {
				width: 100%;
			}
			.status-msg {
				h3 {
					color: @color1;
				}
			}

			.link-url-wrapper {
				.dropdown-menu {
					height: 150px !important;
					overflow-y: auto !important;
					position: relative !important;
					left: 0 !important;
					top: 0 !important;
					width: 100%;
				}
			}
		}
		&-footer {
			margin-top: 20px;

			label {
				text-transform: none;
			}
			div.zoom-password {
				padding-right: 0px;
			}
			.zoom-password div {
				input {
					width: 85%;
				}
			}
		}
	}
}