@import (reference) '~rev-less/global/variables';

.vbBtnPrimary {
	composes: vbUiBtn from './vb-btn-secondary.module.less';
	cursor: pointer;
	position: relative;
	border-width: 1px;
	border-style: solid;

	&:hover,
	&:focus {
		text-decoration: none;
	}

	&:hover:not([disabled]) {
		background: @hoverBackground;
	}

	&[disabled] {
		opacity: .5;
	}

	:global(.glyphicons:not(.theme-accent-inverted)) {
		color: inherit !important;
	}
}
