@import (reference) '~rev-less/global/variables';

.errorMessage {
	border: 1px solid @warningColor;
	background-color: @warningBackground;
	color: @warningColor;
	margin-top: 5px;
	padding: 2px;
	font-size: @type14;
}
.errorMessageWrapper {
	&:global(.ng-active) {
		.errorMessage;
	}

	.message {
		padding: 10px;

		&:before {
			content: '\E193';
			position: relative;
			top: 1px;
			display: inline-block;
			font-weight: 400;
			font-family: Glyphicons Regular;
			line-height: 1;
		}
	}
}
