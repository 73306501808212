@import "global/mixins";
@import "global/variables";

@verticalItemMargin: 5px;
@horizontalItemMargin: 8px;

.flex-navigation-bar-wrap {
	margin-top: @verticalItemMargin;
	margin-bottom: @verticalItemMargin;
	margin-left: @horizontalItemMargin;
}

.flex-navigation-bar {

	margin-top: -@verticalItemMargin;
		@media all and (max-width: @viewPortMedWidth) {
			margin-top: 0;
		}
	margin-left: -@horizontalItemMargin;


	.flexContainer(@wrap:wrap);

	&:not(.mobile-bar) {
		position: relative;
	}

	> div {

		margin-top: @verticalItemMargin;
		margin-left: @horizontalItemMargin;

		&:not(.mobile-row) {
			.flexItem();
		}

		&.flex-fill {
			.flexItem(@flex:1 1 auto)
		}

		h2, h3, h4 {
			color: @color1MedText;
			font-weight: bold;
			text-transform: uppercase;

			a {
				color: @color1MedText;
				font-weight: bold;
				text-transform: uppercase;
			}
		}

		>h2, >h3, >h4, >span {
			margin: 10px 0;
			display: inline-block;
			@media(min-width: 768px){
				margin: 6px 0;
			}

		}
	}

	&.equal-spacing {
		> div {
			&:not(.mobile-row):not(.no-resize)
			{
				.flexItem( 1 1 auto );
			}
		}
	}

	.input-group {
		.flexContainer();

		.form-control {
			.flexItem(@flex:1 1 auto);
			display: block;
			float: none;
		}

		.input-group-btn {
			display: block;
			.flexItem(0 0 38px);
			float: none;
		}
	}

	.flex-button-group {
		.flexContainer(@wrap: wrap);

		> .btn {
			.displayFlex();

			.glyphicons {
				top: 0;
				font-size: @type12;
				margin-right: @typeUnit1-2x;
			}
		}
	}

	.tooltip {
		min-width: 65px;
	}
}

@media(min-width:992px) {
	.flex-navigation-bar {
		&.mobile-bar {
			.flexContainer();
			position: relative;
		}

		> div.mobile-row {
			.flexItem();
			padding-right: 8px;
		}
	}
}
