@import (reference) '~rev-less/global/variables';

.colorSelector {
	height: 30px;
	width: 40px;
	background-color: @white;
}

.resetBtn {
	height: 31px;
}
