@import (reference) '~rev-less/global/variables';

.warning, .error {
	padding: .5rem 1rem;
}

.warning {
	background-color: rgb(255, 243, 205);
	border-color: rgb(255, 238, 186);
}

.lightText {
	color: @white;

	&:hover {
		color: @white;
	}
}
