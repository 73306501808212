@import (reference) "~rev-less/global/variables";

.importZoomGridlist {
	vb-grid-row-collection {
		height: 60vh;

		vb-grid-list-row {

			vb-grid-list-column {
				font-size: @type12;
			}

			vb-grid-list-column:nth-child(2) {
				font-weight: bold;
				font-size: @type14;
			}
		}
	}
}