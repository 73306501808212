@import "global/variables";
@import "global/mixins";


// this is needed because vb-input adds form-control automatically
input[type="checkbox"].form-control {
	.BoxShadow(none);
	height: auto;
	width: auto;
}

vb-input{
	display: block;
	&.hide-success .success-field{
		display: none;
	}

	&[inline] {
		display: inline;
		.vb-input-wrap {
			display: inline-block;
			position: relative;
		}

		.form-group {
			margin: 0;
		}
	}
}

.multi-widget-validation {
	.required-field {
		position: absolute;
		margin-left: 4px;
		top: 0;
		height: 32px;
		line-height: 32px;
		display: block;


		vb-input[inline]& {
			left: 100%;
		}

		@media(min-width: 768px) {
			margin-left: 32px;

			&:before {
				left: -16px;
			}
		}
	}

	.error-field {
		margin-left: 4px;
		line-height: 32px;
		display: block;

		.admin-content vb-input&{
			position: static;
			display: block;
		}

		@media(min-width: 768px) {
			position: absolute;
			top: 0;
			margin-left: 32px;

			vb-input[inline]& {
				left: 100%;
				width: 100%;
			}

			vb-input:not([inline])& {
				right: 3px;
				top: 0;

				label{
					padding: 0;
				}
			}
		}
	}

	.success-field {
		@media(max-width: @viewPortSmallWidth) {
			right: -27px !important;
		}
	}
}
