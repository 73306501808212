.table {
	background: fade(@white, 70%);
    color: @color1LightText;

    th,
    td {
		vertical-align: middle;
	}

	th {
		&.text-align-center {
			text-align: center !important;
		}
	}

	thead th {
		background: rgba(228,229,230,1);
		color: #aaa;
		border: 0;
		text-transform: uppercase;
		font-size: 10px;
		text-align: center;

		&.align-left {
			text-align: left;
		}

		&.selected {
			color: #222;

			.caret {
				border-top-color: #222;
			}
		}

		.caret {
			position: relative;
			top: -2px;
		}
	}

	tbody > tr.new {
		> td,
		> th {
			border-top: none;
		    border-bottom: 1px solid @borderColor;
		    padding: 8px;

		}
	}

	&-striped > tbody > tr:nth-child(odd) {
		> td,
		> th {
			background: fade(@white, 40%);
		}
	}

	&-row-bg th {
		color: fade(@white, 70%) !important;
		font-weight: normal !important;
		text-transform: none !important;
		font-size: 12px !important;
		.VerticalGradient(#787878, #596065) !important;
	}
}

.media-permissions {
	table-layout: fixed;

	tbody {
		.border-right {
			border-right: 1px solid @blackFade05;
		}

		tr.confirm-delete {
			border-right: none;
		}
	}

	tr:hover {
		&:extend(.PointerCursor);
	}

	td {
		text-align: center;
	}

	th.header-icon {
		border-right: none;
		width: 35px;
	}

	.header-icon {
		text-align: left;
	}

	&.table {
		thead > tr {
			> th,
			> td {
				line-height: 1;
				padding: 4px 8px;
				background: @color1;
			}

			> th.selected {
				color: @white;

				.caret {
					border-top-color: @white;
				}
			}
		}

		tbody > tr {
			> th,
			> td {
				padding: 3px 8px;
			    border: solid transparent;
				border-width: 1px 0;

				&.no-padding {
				    padding: 0;
				}
			}
		}

		&-hover > tbody {
			> tr {
				&.recent {
					> th,
					> td {
					    background: #ddf3c9;
					}
				}

				> th,
				> td {
				    border-color: @white;
				}
			}

			> tr.confirm-delete {
				&,
				&:hover {
					> th,
					> td {
						color: #990f0f;
						vertical-align: middle;
						background: fade(@black, 20%);
						position: relative;
					}
				}

				&.reversed {
					&,
					&:hover {
						> th,
						> td {
							color: fade(@white, 80%);
						    vertical-align: middle;
						    position: relative;
						    border: none;
						}
					}
				}
			}

			&.table-subgroups > tbody > tr.selected {
				> td,
				> th {
				    border-top: 2px solid #838b91;
				}
			}

			.media-permissions.table-hover > tbody > tr.selected > td,
			.media-permissions.table-hover > tbody > tr.selected > th
			> tr.selected {
				&:extend(.table-hover > tbody > tr.selected > td);

				&:hover {
					td,
					th {
						&:extend(.table-hover > tbody > tr:hover > td);
					}
				}
			}
		}
	}
}

tr {
	&.confirm-delete {
		th,
		td {
		    position: relative;
		}

		&.reversed {
			.confirm-delete-group {
			    background: #990f0f;
			    display: inline-block;
			    height: 100%;
			    padding: 8px;
			    height: 30px;
			    vertical-align: middle;
			    position: relative;

			   .glyphicons {
				    color: @whiteFade50;
				}

				&:after {
				    content: '';
				    width: 0;
				    height: 0;
				    .TriangleRight(15px, #990f0f);
				    position: absolute;
				    right:-15px;
				    top: 0;
				}
			}

			.btn-ok .glyphicons {
			    color: #990f0f;
			    margin-right:3px;
			}
		}
	}

	&.selected {
		.header-icon {
			position: relative;
		}

		+ tr.first-child {
			.header-icon.triangle-after:after {
				content: '';
				.TriangleTop(7px, @white);
				position: absolute;
				right: 0;
				top: -7px;
			}

			&:hover .header-icon.triangle-after:after {
			    border-color:  transparent transparent rgba(0,161,240,0.1) transparent;
			}
		}
	}
}

.table-hover {
	> tbody {
		> tr:hover {
			td,
			th {
				background: rgba(0,161,240,0.1);
			}
		}

		> tr.show-info {
			> td,
			> th {
				background-color: @white ;
			}
		}

		> tr.selected {
			> td,
			> th {
				background-color: rgba(0,161,240,0.05) ;
			    border: solid rgba(0,161,240,0.1);
			    border-width: 1px 0;
			}
		}

		> tr.last-item {
			td,
			th {
				border-bottom: 1px solid rgba(0,161,240,0.1) !important;
			}
		}
	}
}

.media {
	&.setting {
		.table-tools {
			margin-bottom: 5px;
		}

		&.detail .new-permissions-item {
			.input-group-highlighted.active .input-group-btn.input-group-btn-separate .btn-cancel {
				background: @borderColor;
				color: @blackFade50;
				border-color: @borderColor;
				.BoxShadow(none);
			}

			.input-group-btn-separate:first-child .btn {
				margin-right: 15px;
			}
		}
	}

	.table-hover > tbody tr.item-library-info {
		&:hover,
		&.first-child:hover {
			td,
			th {
				&:extend(.table-hover > tbody > tr:hover > td);
			}
		}

		> td,
		> th {
			background-color: rgba(0,161,240,0.05);
			border-color: rgba(0,161,240,0.05);
		}

		&.first-child {
			> td,
			> th {
				.VerticalGradient(@accentFade20, @accentColor, 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwYTBmMCIgc3RvcC1vcGFjaXR5PSIwLjAyIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMGEwZjAiIHN0b3Atb3BhY2l0eT0iMC4wNSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+');
			}
		}
	}
}

.admin .table {
	.place-holder {
		height: 25px;

		th,
		td {
			border: none;
		}

		td:hover,
		th:hover {
			background: none !important;
		}
	}

	tr.place-holder:hover {
		td,
		th {
			&:extend(.admin .table .place-holder td:hover);
		}
	}
}