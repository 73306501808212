@import (reference) '~rev-less/global/variables';

[full-width] {
	width: 100%;
}

@media (max-width: 991px) {
	[flex="hide"] {
		display: none;
	}

	vb-layout:not([flex="hide"]),
	[layout]:not([flex="hide"]),
	[layout="row"]:not([flex="hide"]) {
		// not using flexContainer so properties can be over-ridden easily
		.displayFlex();
		> vb-layout:not([flex]):not([flex-gt-md]):not([flex="hide"]),
		> div:not([flex]):not([flex-gt-md]):not([flex="hide"]) {
			.flexItem();
		}
	}
	vb-layout[column]:not([flex="hide"]),
	[layout="column"]:not([flex="hide"]) {
		.displayFlex();
		> vb-layout:not([flex]):not([flex-gt-md]):not([flex="hide"]),
		> div:not([flex]):not([flex-gt-md]):not([flex="hide"]) {
			.flexItem();
		}
	}
}

[display="flex"] {
	.displayFlex();
}

vb-layout,
vb-layout[row],
[layout],
[layout="row"]
{
	.flexFlow(row wrap);
	.flexJustifyContent(stretch);
	.alignItems(stretch);
}

vb-layout[column],
[layout="column"]
{
	.flexFlow(column wrap);
	.flexJustifyContent(stretch);
	.alignItems(stretch);
}
[layout-wrap] {
	.flexWrap(wrap);
}
[layout-wrap="false"] {
	.flexWrap(nowrap) !important;
}
[flex] {
	.flexItem();
}
//todo: these are piling up, we should probably use a directive
[flex="fill"] {
	.flexItem(1 1 auto);
}

[flex="shrink"] {
	.flexItem(0 1 auto);
}

[flex="1 1 0"] {
	.flexItem(1 1 0);
	-ms-flex: 1 1 1px; // IE has a bug where it doesn't accept a 0 flex basis without units. Minification strips the units off of 0-values.
}
[flex="1"] {
	.flexItem(1 0 auto);
}
[flex="2"] {
	.flexItem(2 0 auto);
}
[flex="5"] {
	.flexItem(1 1 5%);
}
[flex="15"] {
	.flexItem(1 1 15%);
}
[flex="16"] {
	.flexItem(1 1 16.666667%);
}
[flex="20"] {
	.flexItem(1 1 20%);
}
[flex="25"] {
	.flexItem(1 1 25%);
}
[flex="32"] {
	.flexItem(0 0 32%);
}
[flex="33"] {
	.flexItem(1 1 33.333333%);
}
[flex="45"] {
	.flexItem(1 1 45%);
}
[flex="50"] {
	.flexItem(1 1 50%);
}
[flex="75"] {
	.flexItem(0 0 75%);
}
[flex="100"] {
	.flexItem(0 0 100%);
}

[flex="32px"] {
	.flexItem(0 0 32px);
}
[flex="40px"] {
	.flexItem(0 0 40px);
}
[flex="48px"] {
	.flexItem(0 0 48px);
}
[flex="88px"] {
	.flexItem(0 0 88px);
}
[flex="256px"] {
	.flexItem(0 0 256px);
}

[flex="12%"] {
	.flexItem(12%);
}
[flex="20%"] {
	.flexItem(20%);
}
[flex="30%"] {
	.flexItem(30%);
}

[flex="webcast"] {
	.flexItem(1 1);
}

[flex-wrap="false"] {
	.flexWrap(nowrap) !important;
}

[flex-wrap="true"] {
	.flexWrap(wrap) !important;
}

[flex-align="baseline"] {
	.alignItems(baseline);
}
[flex-align="center"] {
	.alignItems(center);
}
[flex-align="start"] {
	.alignItems(flex-start);
}
[flex-align="end"] {
	.alignItems(flex-end);
}

[flex-justify="start"] {
	.flexJustifyContent(flex-start);
}
[flex-justify="center"] {
	.flexJustifyContent(center);
}
[flex-justify="end"] {
	.flexJustifyContent(flex-end);
}
[flex-justify="space"] {
	.flexJustifyContent(space-between);
}
[flex-justify="space-around"] {
	.flexJustifyContent(space-around);
}
[flex-justify="space-evenly"] {
	.flexJustifyContent(space-evenly);
}
[flex-justify="stretch"] {
	.flexJustifyContent(stretch);
}
[flex-align-self="center"] {
	.alignSelf(center);
}
[flex-align-self="start"] {
	.alignSelf(flex-start);
}
[flex-align-self="end"] {
	.alignSelf(flex-end);
}
[flex-basis="auto"] {
	.flexBasis(auto);
}
.flex-end {
	justify-content: flex-end !important;
}

@media (min-width: 992px) {

	vb-layout,
	[layout],
	[layout="row"],
	[layout-gt-md],
	[layout-gt-md="row"] {
		.displayFlex();

		> div:not([flex]):not([flex-gt-md]):not([flex-gt-md="hide"]) {
			.flexItem();
		}
	}
	vb-layout[column],
	[layout="column"],
	[layout-gt-md="column"] {
		.displayFlex();

		> div:not([flex]):not([flex-gt-md]):not([flex-gt-md="hide"]) {
			.flexItem();
		}
	}

	[layout-gt-md],
	[layout-gt-md="row"]
	{
		.flexFlow(row wrap);
		.flexJustifyContent(stretch);
		.alignItems(stretch);
	}

	[layout-gt-md="column"]
	{
		.flexFlow(column wrap);
		.flexJustifyContent(stretch);
		.alignItems(stretch);
	}

	[flex-gt-md] {
		.flexItem();
	}

	[flex-gt-md="fill"] {
		.flexItem(1 1 auto);
	}
	[flex-gt-md="16"] {
		.flexItem(1 1 16.666667%);
	}
	[flex-gt-md="25"] {
		.flexItem(1 1 25%);
	}
	[flex-gt-md="33"] {
		.flexItem(1 1 33.333333%);
	}
	[flex-gt-md="50"] {
		.flexItem(1 1 50%);
	}

	[flex-gt-md="48px"] {
		.flexItem(0 0 48px);
	}
	[layout-gt-md-wrap="false"] {
		.flexWrap(nowrap) !important;
	}
	[flex-gt-md-align="center"] {
		.alignItems(center);
	}

	[flex-gt-md="hide"] {
		display: none;
	}
}

@media (max-width: (@viewPortMedWidth - 1px)) {
	[layout-lt-md] {
		.displayFlex();
	}

	[layout-lt-md="column"] {
		.flexFlow(column wrap);
		.flexJustifyContent(stretch);
		.alignItems(stretch);
	}

	[flex-lt-md="fill"] {
		.flexItem(1 1 auto);
	}
}
