@import "global/mixins";
@import "global/variables";

// new flexbox version
.flex-navigation-bar {
	.btn-back {
		margin-right: 8px;
		padding: 0.25rem 0.5rem;
		.glyphicon {
			color: @color1LightText;
		}
	}


	.breadcrumb {
		margin: 0;
		padding: 0;
		line-height: 36px;

		ol& > li {
			&:extend(.TextClip);
		}

		a, .btn {
			color: @color1MedText;
		}
		
		@media (max-width: 767px) {
			& > li + li:before{
				content: initial;
			}
		}
		@media (min-width: 768px) {
			.breadcrumb-item + .breadcrumb-item:not(.breadcrumb-ellipsis) {
				&:before {
					padding: 0 4px 0 6px;
					color: @color1LightText;
					content: "/";
				}
			}
		}
	}
}

// original bootstrap version
.breadcrumb {
	padding: 8px;
	list-style: none;
	background: none;
	.RoundedShape(4px);

	&-row {
		border-bottom: 1px solid @borderColorLight;
		margin-bottom: 16px;
		background: @backgroundLighten03;
		color: @color1MedText;
		height: 36px;
		line-height: 36px;

		h4 {
			margin: 0;
		}

		.btn-white {
			color: @color1MedText;
			background: none;
			border-color: @borderColor;
		}

		.btn {
			padding: 0.25rem 0.5rem;
		}

		.form-group {
			padding: 0;

			.btn {
				padding: 0.25rem 0.5rem;
			}
		}

		.breadcrumb {
			padding: 0;
			margin: 0;
			border: none;
			color: @color1MedText;
			font-size: @type16;

			li:first-child a {
				padding-right: 3px;
			}

			> li + li:before {
				padding: 0 5px 0 5px;
			}

			a {
				color: @color1MedText;
			}
		}
	}
}
