/**
 * Older file that could potentially be removed.
 */
.tag {
	font-size: 11px;
	text-transform: uppercase;

	&.required {
		color: @warningColor;
	}
}

.preview-image {
	.BoxShadow(0 0 4px fade(@black, 30%));
}

@media (max-width: 480px) {
	.rating {
		display: block;
	}
}