.scroll-container {
	min-height: 200px;
	overflow: hidden;
	overflow-y: scroll;
	padding: 0 16px;
}

.scroll-container-auto {
	&:extend(.scroll-container);
	overflow-y: auto;
}

.vb-scrollbar {
	scrollbar-color: var(--theme-primary-txt, rgba(0, 0, 0, .5)) var(--theme-primary);
	scrollbar-width: thin;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 8px;
		height: 8px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: var(--theme-primary-txt, rgba(0, 0, 0, .5));
	}
}
