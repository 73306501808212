@import "global/variables";
@import "global/mixins";

.video {
	&-duration-overlay {
		background: @color1Fade50;
		color: @white;
		font-size: 10px;
		padding: 0 8px;
		position: absolute;
		top: 0;
		right: 0;
		line-height: 18px;
		height: 18px;
	}
}

.glyphicons {
	.navbar-admin-main .label & {
		top: -1px;
		vertical-align: middle;
	}
}

.video-error,
.error-msg,
.required-icon,
.alert-danger {
	color: @warningColor;
}

.clean-alert {
	background: none;
	margin-bottom: 15px;

	> strong {
		padding-left: 40px;
	}
}

.label-status-active {
	color: @whiteFade75;
	background: #00af43;
	font-size: 14px;
	font-weight: 500;
	.RoundedShape(3px);
	border: 1px solid fade(@black, 35%);
	vertical-align: middle;
	padding: 7px 12px;
}

.live-badge {
	position: absolute;
	top: 4px;
	right: 4px;
	background: rgba(0,85,128,1);
	color: @white;
	text-transform: uppercase;
	font-weight: bold;
	padding: 0 5px;
	font-size: 10px;
	line-height: 17px;
	.RoundedShape(3px);
	color: #96dcff;
	letter-spacing: 1px;
}