@import "../global/variables";
@import "../global/mixins";

/**
 * This file only used by the mobile versions of the carousels on the home page and calendar page
 */

.event-list {
	.carousel-mobile-list {
		.preview-cell {
			width: 56px;
			padding: 4px 0;

			.circle {
				margin-left: 8px;
			}
		}
	}
}

.carousel-mobile-list {
	width: 100%;
	background: @white;
	.RoundedShape(2px);
	.BoxShadow(0 0 1px @blackFade25);
	table-layout: fixed; /* IE */

	.no-items & {
		background: @whiteFade50;
		.BoxShadow(none);
	}

	th,
	td {
		background: none;
		border-bottom: 1px solid @borderColorLight;
	}

	.preview-cell {
		width: 80px;
		padding: 4px 8px 4px 4px;
		vertical-align: middle;
	}

	.image-wrapper {
		width: 73px;
		height: 40px;
	}

	.title {
		padding: 4px 8px 4px 4px;
		overflow: hidden;
		&:extend(.TextClip);
		color: @color1LightText; // for ellipsis
	}

	tr:hover {
		cursor: pointer;

		.title a {
			color: @accentColor;
			text-decoration: none;
		}

		img {
			opacity: 0.9;
		}

		.preview-cell.event-tile {
			.fixed-ratio {
				background: @accentColor;
			}

			h1 {
				color: @white;
			}
		}
	}

	.fixed-ratio {
		background: @color1;
		.TransitionAll(all .3s);
	}

	.team-logo-href {
		background: transparent;
	}

	h1, h2, h3, h4, h5, h5, a
	{
		color: @color1MedText;
	}
}

.no-items .carousel-mobile-list {
	h1, h2, h3, h4, h5, h5, a
	{
		color: @color1LightText;
	}

	th.title {
		padding-right: 15px;
	}

	.title a,
	.title a:hover,
	tr:hover .title a,
	tr:hover {
		color: @color1LightText;
		text-decoration: none;
		cursor: default;

		&:extend(.WordBreak);
	}
}
