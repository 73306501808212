@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root {
	display: block;
}

.content {
	display: flex;
	:global(.bs-datepicker) {
		box-shadow: none !important;
	}
}

.calendarIcon {
	top: 2px;
	margin-right: 5px;
}

.sidebar {
	width: 200px;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	background: @white;
	border-right: 1px solid @borderColor;

	a {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		padding-left: 30px;
	}
}
