@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

@selectBtnWidth: 40px;
@focusErrorBorderPadding: 6px @selectBtnWidth 8px 8px;

.customSelect {
	.RoundedShape();
	background: @white;
	border-style: solid;
	display: block;
	position: relative;
	height: 40px;
	overflow: hidden;

	select {
		background: @white;
		.BoxSizing(border-box);
		border: 1px solid transparent;
		color: @primaryDark;
		cursor: pointer;
		font-size: @type14;
		line-height: 1.6;
		margin: 0;
		outline: none;
		padding: 6px (@selectBtnWidth + 2) 6px 10px;
		width: 100%;
		height: 100%;
		overflow: hidden;
		.Appearance(none);
	}

	&:after,
	&:before {
		content: " ";
		pointer-events: none;
		position: absolute;
		z-index: 2;
	}

	&:after {
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		display: block;
		height: 0;
		margin-top: -3px;
		right: 1em;
		top: 50%;
		width: 0;
	}

	&:before {
		height: 100%;
		right: 0;
		top: 0;
		width: @selectBtnWidth;
	}


	&:not(.focus) {
		border: 1px solid @lightBorder;

		&.error {
			border: @invalidFieldBorder;

			select {
				padding: @focusErrorBorderPadding;
			}
		}
	}

	&.focus {
		border-width: 3px;
		.BoxShadow(inset 0px 0px 5px 0px @blackFade25);
		outline: 0;
		border-right: 0;

		select {
			padding: @focusErrorBorderPadding;


			&:-moz-focusring { // hide the focus outline in Firefox
				color: transparent;
				text-shadow: 0 0 0 @black;
				padding: 3px (@selectBtnWidth + 2) 3px 8px;
			}
		}
	}
	select:disabled {
		pointer-events: none;
	}
}

/* IE 10/11+ - This hides native dropdown button arrow so it will have the custom appearance. Targeting media query hack via http://browserhacks.com/#hack-28f493d247a12ab654f6c3637f6978d5 - looking for better ways to achieve this targeting */
/* The second rule removes the odd blue bg color behind the text in the select button in IE 10/11 and sets the text color to match the focus style's - fix via http://stackoverflow.com/questions/17553300/change-ie-background-color-on-unopened-focused-select-box */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.customSelect {
		select {
			border: 1px solid transparent;
			padding-right: 2em;

			&:focus {
				padding-bottom: 6px;
				padding-top: 6px;
			}

			&::-ms-expand {
				display: none;
			}

			&::-ms-value { // override the blue selected/focused state
				background: none;
				color: @primaryDark;
				padding-right: 4rem;
			}
		}

		&:after {
			display: block;
		}
	}
}
