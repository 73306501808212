@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";
@import (reference) "bootstrap/less/mixins/tab-focus";

[uib-dropdown-menu].align-right {
	left: auto;
	right: 0;

	.BorderTopLeftRadius(4px);
	.BorderTopRightRadius(0);
}

/* bootstrap override of showing scrollbar always */
.dropdown-menu {
	&.navdrop-menu .scrollable-area {
		overflow-y: auto !important;
	}

	> li > .dropdown-item {
		width: 100%;
		padding: 3px 20px;
		border: none;
		background: none;
		color: #333;
		text-align: left;
		line-height: 1.45;

		&:hover {
			color: #262626;
			text-decoration: none;
			background-color: #f5f5f5;
		}
	}
}

.dropdown-menu-right {
	left: auto !important;
}


.open {
	.dropdown-menu {
		&.width-100 {
			min-width: 110px;
		}
	}
}

.dropdown-toggle:focus {
	outline: 5px auto;
	.tab-focus();
}

.dropdown-list {
	&-item {
	.RoundedShape(0);

		/* some specificity overriding that has to be done for now */
		> .btn {
			border-color: @white !important;

			&:hover, &.active {
				color: @white !important;
				background-color: @accentColor !important;
			}
		}
	}

	&-link {
		text-align: left;
	}
}
