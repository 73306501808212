.media-edit-content {
    height: 100%;

    .new-panel {
        background: @color2;

        &-footer .btn-group {
            margin-top: 8px;
        }
    }

    .combined-toolbar .table-cell.text-align-right {
        padding-left: 15px;
    }

    .modal-footer textarea {
        padding: 4px 8px;
        margin-top: 15px;
    }

    .btn + a {
        margin-left: 0;
    }

	.nav-tabs {
		margin: 0 8px -1px;

		> li.active > a {
			background: @color2;
			border-color: @borderColor;
			border-bottom-color: transparent;
		}

		> li:not(.active) > a:hover {
			color: @color1;
			background: @borderColor;
		}

        @media(min-width: 768px) {
            margin: 0 16px -1px;
        }
	}

	.tab-content {
		margin: 0 8px;
		border: 1px solid @borderColor;

        @media(min-width: 768px) {
            margin: 0 16px 16px;
        }
	}

    .tab-pane {
        &-caption {
            padding: 15px;
        }
    }
}
