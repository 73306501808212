@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.gridList {
	display: block;

	header,
	vb-grid-row-collection > .gridRow {
		&:extend(.flexDisplay);
		.alignItems(center);

		> div, > .gridColumn {

			vertical-align: middle;

			&:not([flex]){
				.flex(1);
			}

			&[align=right] {
				text-align: right;
			}

			&[align=center] {
				text-align: center;
			}
		}
	}

	header {
		font-weight: bold;
		padding: 0 16px 0 0; // scroll bar
	}

	vb-grid-row-collection {
		display: block;
		padding: 0;
		overflow-y: scroll;

		.gridRow {
			margin-top: 1em;

			&:global(.selectable-item):hover {
				.PointerCursor;
			}
		}
	}

	[flex="fill"] {
		.flexItem(2 1);
	}

	.gridRow:global(.ng-move),
	.gridRow:global(.ng-enter),
	.gridRow:global(.ng-leave) {
		.TransitionAll();
	}

	.gridRow:global(.ng-enter) {
		opacity: 0;

		&:global(.ng-enter-active) {
			opacity: 1;
		}
	}
	.gridRow:global(.ng-leave) {
		opacity: 1;

		&:global(.ng-leave-active) {
			opacity: 0;
		}
	}
	.gridRow:global(.ng-move) {
		opacity: 0;
		max-height: 0;

		&:global(.ng-move-active) {
			opacity: 1;
			max-height: 48px;
		}
	}
	.selectionColumn {
		.flex(none) !important;
		width: 32px;
		padding: 8px 4px 0;

		input {
			margin: 0;
		}
	}

	.reorderRow {

		:global(.btn.btn-svg-caret) {

			padding: 0;
			display: block;
			margin: 0 auto;

			svg {
				width: 12px;
				height: 12px;
				fill: @color1LightText;
			}

			&:hover {
				svg {
					fill: @color1;
				}
			}
		}

		:global(.vb-draggable) {
			width: 14px;
			margin: 0 auto;
		}
	}

	.toolbarColumn {

		text-align: right;

		button, a {
			margin-left: 2px;
		}
	}

	.emptyMsg {
		font-size: 1.25em;
		text-align: center;
		padding: 60px 0;
	}
}

.gridList:global(.white-items) {

	vb-grid-row-collection {
		margin-right: 0;

		> .gridRow {
			background: @white;
			border-bottom: 1px solid @borderColorLight;
			margin: 0;
			padding: 6px 0;

			&:global(.selected) {
				background: @accentColor05;
				color: @accentDarken10;
			}

			> .gridColumn {
				padding: 0 4px;
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}

	header {
		color: @color1LightText;

		> div {
			padding: 8px 4px;
			font-weight: normal;
			text-transform: uppercase;
			font-size: @type12;
		}
	}

	.toolbarColumn {
		.btn {
			&:extend(.BtnWhite);
		}
	}
}

