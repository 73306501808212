/* TODO: this is all legacy and should be factored out */

.tree {

	&-object {
		padding: 0 15px;
		position: relative;

		.btn-tree-control {
			background: #464c50;
			color: rgb(154,212,241);
			border: none;
			font-size: 10px;
			.RoundedShape(2px);
			height: 16px;
			width: 16px;
			text-align: center;
			padding: 0;
			margin: 0;
			min-height: 16px;

			&:hover,
			&:active {
				color: rgba(154,212,241,0.8);
			}

			.glyphicons {
				position: relative;
				left: 0;
				top: auto;
				line-height: normal;
				min-height: 16px;
			}
		}

		.item-count,
		.item-type	{
			text-align: left;
			min-width: 100px;
			white-space: nowrap;
		}

		.expand-icon  {
			position: absolute;
			left: -8px;
			top: 10px;
		}
	}

	&-item.combined-toolbar {
		.RoundedShape(4px);
		height: 41px;
		min-height: 41px;
		.BoxShadow(0 1px 0 fade(@black, 20%), 0 0 2px @borderColor, inset 0 1px 0 @white;);
		background: @white;
		border: none;
		margin: 1px 0;

		.ff-wrap-fix {
			position: relative;
			width: auto;
			height: 31px;
			min-height: 31px;
			text-align: left;
		}

		/* async loading */
		&.tree-item-loading {
			text-align: left;
			height: 39px;
			min-height: 39px;
			opacity: 0.5;

			.table-cell-library-name {
				font-weight: normal;
			}
		}

		&[disabled] {
			opacity: 0.5;
		}

		&.error {
			background: @warningColorFade50;
		}

		> div {
			padding: 5px;
			vertical-align: middle;

			&.table-cell-library-name {
				padding-left: 15px;
			}

			> a {
				display: block;
			}

			&:first-child  {
				vertical-align: middle;
				padding-left: 16px;
			}

			&:last-child {
				padding-right: 16px;
			}

			/* over-riding legacy code */
			&.drag-handle {
				margin: 0;
				padding: 5px 0 5px 5px;
				display: table-cell;

				.btn {
					color: fade(@black, 30%);
					font-size: 10px;
					text-shadow: 0 1px @white;
					padding-right: 0;

					&:focus,
					&:active {
						color: fade(@black, 80%);
						background: none;
						.BoxShadow(none);
					}
				}
			}
		}

		&:hover {
			background: rgb(243,251,255);
		}
	}

	/* item error */
	&-item-error-overlay {
		position: absolute;
		left: 15px;
		right: 0;
		top: 0;
		bottom: 0;
		background: fade(@warningColor, 90%);
		vertical-align: middle;
		z-index: 10;
		color: @white;
		vertical-align: middle;
		line-height: 41px;
		padding: 0 15px;
		.RoundedShape(3px);

		.close {
			color : @white;
			opacity: 0.7;
			font-weight: normal;
			text-shadow: none;
			margin-top: 7px;
		}
	}
}

.admin {
	.disabled .combined-toolbar.tree-item > div {
		background: @whiteFade50;
	}

	.combined-toolbar.tree-item:hover.disabled:hover > div {
		background: #f2f2f2;
	}
}

.table-cell-library-name .loading.inline.small {
	top: 4px;
	margin-right: 5px;
	left: 0;
	margin-left: 0;
}


.ldap-import .mobile-background-wrap > div:first-child {
	margin-top: 25px;
}

@media(max-width: 600px) {
	.mobile-background-wrap {
		background: @white;
	}

	.tree {
		&-item.combined-toolbar {
			height: auto;
			.RoundedShape(0);
			background: none;

			/* async loading */
			&.tree-item-loading {
				background: none !important;
			}

			.table-cell .btn-toolbar .btn {
				display: inline-block !important;
				width: auto;
				margin-bottom: 0;
			}
		}

		&-object {
		    padding: 0;

			.inline-create-form {
				padding: 0;

				.table-cell {
					display: block;
				}
			}
	    }

	   	&-list-wrapper {
		    margin: 0;
	    }

	    &-item-error-overlay {
			left: -6px;
			line-height: 46px;
			.RoundedShape(0);
		}
	}
}

@media (max-width: 991px) {
	.combined-toolbar.tree-item .table-cell .btn-toolbar .glyphicons {
		margin-left: 3px;
	}
}