@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

.root:global(.ng-invalid):not(:global(.ng-untouched)):not(.hasFocus) {
	.dateInput,
	.dateBtn {
		border-bottom: @invalidFieldBorder !important;
		border-top: @invalidFieldBorder !important;
	}

	.dateInput {
		border-left: @invalidFieldBorder;
	}

	.dateBtn {
		border-right: @invalidFieldBorder !important;
	}
}

.dateWrapper {
	.displayFlex();

	:global(.dropdown-menu) {
		color: @black;
		padding: 0;
		margin-left: 32px;

		thead :global(.btn-sm) {
			font-weight: normal;
			padding: 12px 10px;
		}

		:global(.btn) {
			border: none;
			background-color: transparent;
			.RoundedShape(0);
			.BoxShadow(none);

			span {
				padding: 5px 8px;
			}
		}

		:global(.btn:not(.active) span.text-info) {
			font-weight: bold;
			color: @primaryDark;
			border: 1px solid @lightBorder;
			background-color: transparent;
		}

		:global(.datePickerSelectedDate) button span {
			font-weight: bold;
			border: 1px solid @lightBorder;
		}
	}

	> input {
		.RoundedLeftShape(@defaultBorderRadius);
	}
}

.today {
	font-weight: bold;
	color: @primaryDark;
	border: 1px solid @lightBorder;
	background-color: transparent;
}

.dateInput {
	.flex(1 1 auto);
}

.dateBtn {
	height: 100%;
	padding: 5px 10px;
	.RoundedRightShape(4px);
	border: 1px solid @lightBorder !important;
	border-left-style: none !important;
	width: 40px;
}
