@import "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.event-container{
	overflow-y: auto;
}

.events {

	.event-info-wrap {
		min-height: 100vh;
	}

	.event-date { //in webcast-header.html
		@media(min-width: @viewPortVerySmallWidth) {
			white-space: normal;
			word-break: break-all;
		}
		@media(min-width: @viewPortSmallWidth) {
			white-space: nowrap;
			word-break: normal;
		}
	}

	.alert {
		margin-bottom: 0;
		&-size-6 {
			width: 48%;
		}
		@media all and (max-width: 767px) {
			width: 100%;
		}
	}
}

.fake-event-nav { //calendar + user notifications
	position: absolute;
	left: 0;
	top: 48px;
	right: 0;
	height: 48px;
	background: @backgroundLighten03;
	border-bottom: 1px solid @blackFade10;

	h2 {
		margin: 0;
	}
}
