.themedCloseBtn {
	&,
	&:focus
	&:hover {
		opacity: 1 !important;
	}
}

.rememberCheckbox {
	margin-top: 2rem;
}