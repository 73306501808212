@import (reference) "~rev-less/global/variables";
@import (reference) "~rev-less/global/mixins";

.sidebar-item {
	&-light {
		border-left: 1px solid var(--theme-primary-txt) !important;
	}
}

.right-sidebar-content {
	order: 1;
	border-left: 1px solid;
}

.right-sidebar {
	&:extend(.flexDisplay);

	position: fixed;
	bottom: 0;
	left: 0;
	min-width: 0; // Firefox flex bug workaround
	width: 100%;
	height: unit(@rightSideClosedWidth, px);

	.TransitionAll(all linear 0.125s);
	z-index: 10;

	@media(max-width: 991px) {
		.sidebar-buttons{
			a, button:not(.dropdown-item) {
				&.active{
					color: @accentColor;
				}
			}

		}
	}

	&:not(.is-open) {
		.right-sidebar-content {
			width: 0;
		}
	}

	&.is-open {
		height: 100%;
		top: 0;

		@media(max-width: 991px) {
			z-index: 1030;

			.right-sidebar-content {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 48px;
				height: auto !important;
			}
		}

		.sidebar-buttons {
			border-left: 1px solid var(--theme-primary-txt-fade-50);

			a, button:not(.dropdown-item) {
				&.active {
					color: @accentColor;
				}
			}
		}

		&:after {
			content: '';
			position: absolute;
			top:-6px;
			bottom: 0;
			left: 0;
			height: 6px;
			.VerticalGradient(@blackFade00, @blackFade15);
		}

		.right-sidebar-mobile-close {
			display: block;
			position: fixed;
			top: @typeUnit3-4x;
			right: @typeUnit1-2x;
			font-size: @type16;
			background-color: transparent;
			border: none;
			color: @color1MedText;
			z-index: 5;
		}

		.right-sidebar-content {
			padding: 56px 16px 16px;

			.right-sidebar-content-header {
				font-size: @type14;
				font-weight: bold;
				margin: 0;
				.AbsoluteFill;
				bottom: auto;
				height: 41px;
				padding: 0 16px;
				line-height: 41px;
			}
		}
	}

	.sidebar-buttons {
		order: 2;
		list-style: none;
		padding: 0;
		text-align: center;
		width: 100%;
		position: fixed;
		bottom: 0;
		right: 0;
		left: 0;
		margin: 0;

		@media(max-width: 991px) {
			.flexContainer;
		}

		border-top: 1px solid @borderColorLight;

		.btn-list {
			display: inline-block;
			width: 5em;

			@media(max-width: 991px) {
				display: flex;
				.flexItem(1);

				a, button:not(.dropdown-item) {
					.flexItem(1);
				}
			}

			a, button:not(.dropdown-item) {
				display: block;
				line-height: 48px;
				color: inherit;
				width: 48px;
				font-size: @type16;

				&:hover {
					color: @color1MedText;
					text-decoration: none;
					> svg {
						fill: @color1MedText;
					}
				}

				&:active {
					color: @accentColor;
					> svg {
						fill: @accentColor;
					}
				}

				> svg {
					display: inline-block;
					height: 20px;
					width: 20px;
					margin: 0 auto 7px;
					vertical-align: middle;
					fill: @color1LightText;
				}

				&.active > svg {
					fill: @accentColor;
				}


			}

			button {
				background-color: transparent;
				border:  none;
				margin: 0;
				padding: 0;
				width: 100%;

				&[disabled], &.disabled, &.disabled:hover {
					color: var(--theme-primary-txt-fade-50);
					cursor: default;
				}
			}

			.real-time-analytics-link {
				.vb-icon-realtime-analytics {
					line-height: inherit;
				}
			}

			.vb-icon-q-and-a {
				font-size: @type18;
			}
		}

		.sidebar-notification {
			position: absolute;
			right: .5em;
			bottom: .75em;

			@media(max-width: 991px) {
				position: relative;
				bottom: -.5em;
				right: .5em;
			}

			&.sidebar-notification-count {
				background-color: @accentColor;
				color: @white;
				.RoundedShape(4px);
				padding: 3px;
				font-size: @type10;
				min-width: 1.5em;
				line-height: 1.25em;
			}

			&.sidebar-notification-flag {
				color: @accentColor;
				bottom: -.75em;
				right: .25em;
			}
		}
	}

	.right-sidebar-mobile-close {
		display: none;
	}
}

@media(min-width: 992px) {
	.right-sidebar {
		height: 100%;
		position: relative;

		&.is-open:not(.force-closed) {
			.flexItem(0 0 unit(@rightSideOpenWidth, px)) !important;

			&:after {
				content: '';
				position: absolute;
				top:0;
				bottom: 0;
				left: -4px;
				width: 4px;
				.HorizontalGradient(@blackFade00, @blackFade10);
				height: 100%;
			}
		}

		.right-sidebar-content {
			position: relative;
			width: unit(@rightSideOpenWidth - 48, px);  // adjust for navbar
			// min-height: 100vh;
		}

		.sidebar-buttons {
			top: 16px;
			width: unit(@rightSideClosedWidth - 1, px); // adjust for border
			position: absolute;
			border-top: 0;
			right: 0;
			left: auto;

			a, button:not(.dropdown-item) {
				width: unit(@rightSideClosedWidth, px);
				position: relative;
			}
		}
	}
}
