@import (reference) '~rev-less/global/variables';
@import (reference) '~rev-less/global/mixins';

/*=============================================================================
TODO: We will have to address using these overrides added in here so we do not
have to mutate so much
=============================================================================*/

.util {
	&-break-word {
		word-wrap: break-word;
	}

	&-clear-both {
		clear: both;
	}

	&-lowercase {
		text-transform: lowercase;
	}

	&-nobreak, &-nobreak > a {
		white-space: nowrap;
	}

	&-no-left-padding {
		padding-left: 0;
	}

	&-uppercase {
		text-transform: uppercase !important;
	}

	&-white-space-normal {
		white-space: normal !important;
	}

	&-white-space-pre {
		white-space: pre;
	}

	&-white-space-pre-line {
		white-space: pre-line;
	}

	&-white-space-pre-space {
		white-space: pre-space;
	}

	&-white-space-pre-wrap {
		white-space: pre-wrap;
	}

	&-vertical-align-center {
		vertical-align: middle !important;
	}

	&-underline {
		text-decoration: underline;
	}

	&-no-underline {
		text-decoration: none !important;
	}

	&-word-break {
		&-all {
			word-break: break-all;
		}
	}

	&-absolute-fill {
		.AbsoluteFill;
	}
}

.root-stacking-context{
	position: relative;
	z-index: 0;
}
.position {
	&-absolute {
		position: absolute;
	}
	&-relative {
		position: relative;
	}
	&-fixed {
		position: fixed;
	}
}

.box {
	&-block {
		display: block;
	}
	&-inline-block {
		display: inline-block;
	}
	&-hide {
		display: none;
	}
	&-inline {
		display: inline;
	}
	&-inline-imp {
		display: inline !important;
	}
	&-flex {
		.displayFlex();
	}
}

.width {
	&-full {
		width: 100%;
	}
	&-auto {
		width: auto !important;
	}
}

.height {
	&-full {
		height: 100%;
	}
}

/*=============================================================================
 +Background Overrides & Helpers
 +=============================================================================*/

.bg {
	&-none {
		background-color: transparent;
	}
	&-white {
		background-color: @white !important;
	}
	&-dark-50 {
		background-color: rgba(0, 0, 0, .5);
	}
}

/*=============================================================================
Border Overrides & Helpers
=============================================================================*/

.border {
	&-bottom {
		&-0 {
			border-bottom: none !important;
		}
	}
}

/*=============================================================================
Fonts
=============================================================================*/

.font {
	&-20 {
		font-size: 20px;
	}
}

/*=============================================================================
Margin Overrides
=============================================================================*/

.margin {
	&-0 {
		margin: 0;
	}
	&-5 {
		margin: 5px !important;
	}

	&-top {
		&-0 {
			margin-top: 0 !important;
		}
		&-5 {
			margin-top: 5px !important;
		}
		&-10 {
			margin-top: 10px !important;
		}
		&-15 {
			margin-top: 15px;
		}
		&-20 {
			margin-top: 20px;
		}
		&-30 {
			margin-top: 30px;
		}
		&-minus5 {
			margin-top: -5px;
		}
		&-minus10 {
			margin-top: -10px;
		}
	}

	&-bottom {
		&-0, &-0.well {
			margin-bottom: 0 !important;
		}
		&-5 {
			margin-bottom: 5px;
		}
		&-10 {
			margin-bottom: 10px !important;
		}
		&-15 {
			margin-bottom: 15px;
		}
		&-20 {
			margin-bottom: 20px;
		}
	}

	&-right {
		margin-right: 5px;
		&-0 {
			margin-right: 0 !important;
		}
		&-3 {
			margin-right: 3px !important;
		}
		&-5 {
			margin-right: 5px
		}
		&-10 {
			margin-right: 10px !important;
		}
		&-15 {
			margin-right: 15px !important;
		}
		&-30 {
			margin-right: 30px !important;
		}
	}

	&-left {
		margin-left: 5px;
		&-auto {
			margin-left: auto !important;
		}
		&-0 {
			margin-left: 0 !important;
		}
		&-5 {
			margin-left: 5px !important;
		}
		&-10 {
			margin-left: 10px;
		}
		&-12 {
			margin-left: 12px;
		}
		&-15 {
			margin-left: 15px !important;
		}
		&-16 {
			margin-left: 16px;
		}
		&-30 {
			margin-left: 30px;
		}
	}
}

/*=============================================================================
Padding Overrides
=============================================================================*/

.padding {
	&-full {
		&-1 {
			padding: 1px;
		}
		&-5 {
			padding: 5px !important;
		}
		&-10 {
			padding: 10px !important;
		}
	}
	&-right {
		&-0 {
			padding-right: 0 !important;
		}
		&-5 {
			padding-right: 5px !important;
		}
		&-10 {
			padding-right: 10px !important;
		}
		&-15 {
			padding-right: 15px !important;
		}
		&-16 {
			padding-right: 16px !important;
		}
		&-20 {
			padding-right: 20px !important;
		}
		&-36 {
			padding-right: 36px !important;
		}
	}

	&-left {
		&-0 {
			padding-left: 0 !important;
		}
		&-5 {
			padding-left: 5px !important;
		}
		&-8 {
			padding-left: 8px !important;
		}
		&-10 {
			padding-left: 10px !important;
		}
		&-12 {
			padding-left: 12px !important;
		}
		&-15 {
			padding-left: 15px !important;
		}
		&-16 {
			padding-left: 16px !important;
		}
		&-18 {
			padding-left: 18px !important;
		}
		&-20 {
			padding-left: 20px !important;
		}
		&-25 {
			padding-left: 25px !important;
		}
		&-30 {
			padding-left: 30px !important;
		}
		&-32 {
			padding-left: 32px !important;
		}
		&-42 {
			padding-left: 42px !important;
		}
	}

	&-top {
		&-0 {
			padding-top: 0 !important;
		}
		&-1 {
			padding-top: 1px !important;
		}
		&-2 {
			padding-top: 2px !important;
		}
		&-5 {
			padding-top: 5px !important;
		}
		&-8 {
			padding-top: 8px !important;
		}
		&-10 {
			padding-top: 10px !important;
		}
		&-15 {
			padding-top: 15px !important;
		}
		&-20 {
			padding-top: 20px !important;
		}
		&-30 {
			padding-top: 30px !important;
		}
	}

	&-bottom {
		&-0 {
			padding-bottom: 0 !important;
		}
		&-5 {
			padding-bottom: 5px !important;
		}
		&-10 {
			padding-bottom: 10px !important;
		}
		&-16 {
			padding-bottom: 16px !important;
		}
		&-20 {
			padding-bottom: 20px !important;
		}
	}

	&-0 {
		padding: 0 !important;
	}
	&-16 {
		padding: @type16;
	}
}

/*=============================================================================
Event Helpers
=============================================================================*/
.cursor {
	&-default {
		cursor: default;
	}
}

/*=============================================================================
Visibility Helpers
=============================================================================*/
.visibility {
	&-hidden {
		visibility: hidden;
	}
}

/*=============================================================================
Type Helpers
/*===========================================================================*/
.type {
	&-bold {
		font-weight: bold !important;
	}

	&-italic {
		font-style: italic;
	}

	&-normal {
		font-weight: normal !important;
	}
	&-10 {
		font-size: @type10;
	}
	&-12 {
		font-size: @type12;
	}
	&-13 {
		font-size: @type13;
	}
	&-14 {
		font-size: @type14;
	}
	&-20 {
		font-size: @type20;
	}
}

/*=============================================================================
Height and Width Helpers
=============================================================================*/

.height {
	&-min-auto {
		min-height: auto !important;
	}
	&-min-400 {
		min-height: 400px !important;
	}
}

.absolute {
	&-top {
		&-5 {
			top: 5px;
		}
	}
}

.text {
	&-ellipsis {
		.TextClip();
	}

	&-overflow-hide {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&-align-center {
		text-align: center;
	}

	&-align-left {
		text-align: left;
	}

	&-align-right {
		text-align: right;
	}

	&-white {
		color: @white;
	}

	&-color-inherit {
		color: inherit !important;

		&:focus,
		&:hover {
			color: inherit;
		}
	}
}

.line-height {
	&-normal {
		line-height: 1.4 !important
	}
	&-2 {
		line-height: 2;
	}
}

.divider {
	&-gray20 {
		border-bottom: 1px solid @gray20;
	}
}
.flex-box {
	&-point-5 {
		.flex(.5) !important;
	}
	&-1 {
		.flex(1) !important;
	}
	&-1-point-5 {
		.flex(1.5) !important;
	}
}

.radius {
	&-3 {
		.RoundedShape(3px);
	}
}

.color {
	&-warning {
		color: @warningColor !important;
	}
	&-caution {
		color: @alertColor;
	}
	&-success {
		color: @green;
	}
}
.float {
	&-left {
		float: left;
	}
	&-right {
		float: right;
	}
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.vb-icon-center {
	&:before {
		vertical-align: middle !important;
	}
}

.seperator {
	border-bottom: 1px solid @borderColor;
}

/*=============================================================================
Animation
=============================================================================*/
.stop-animation-flickering {
	.BackfaceVisibility(hidden);
}

@media all and (max-width: @viewPortLargeWidth) {
	.visible-lg-inline {
		display: none !important;
	}
}

@media (min-width: @viewPortSmallWidth) {
	.pull-right-sm {
		float: right;
	}
}

/*=============================================================================
Labels, Errors, Inline Content Overrides
=============================================================================*/
.has-error {
	.label {
		&-light {
			color: @white !important;
		}
	}
}

/*=============================================================================
Viewport Specific DOM Manipulation
=============================================================================*/

@media all and (max-width: @viewPortMobileDeviceWidth) {
	.xs-hide {
		display: none;
	}
}

@media (max-width: (@viewPortMedWidth - 1px)) {
	.sm-hide {
		display: none !important;
	}
}
