@import (reference) '~rev-less/global/variables';

branding-logo {
	display: block;
	padding: 4px 0;

	img {
		height: 40px;

		@media all and (max-width: @viewPortMedWidth - 1) {
			height: 36px;
		}
	}
}
