@import "global/variables";
@import "global/mixins";

.table {
	thead,
	tbody,
	tfoot {
		> tr > td.active
		> tr > th.active
		> tr.active > th
		> tr.active > td {
			background-color: #dce8f0;
			border-top: 1px solid #2a6496;
			border-bottom: 1px solid #2a6496;
		}
	}

	&-tools .col-12 {
		padding: 15px;
		background: #e0dfdb;
	}
}