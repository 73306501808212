@import "global/variables";
@import "global/mixins";

.panel .fieldset {
	margin: 15px 0;
}

.form-group {
	padding: 6px 0;
	margin-bottom: 0;
	border-color: #fafafa;
	border: none;

	&:last-child {
		border-bottom: none;
	}

	&-highlighted {
		background: #e4e7ec;
		margin: 0 0 15px 0;
		.RoundedShape(4px);
		color: rgb(0,85,128);

		label {
			color: rgb(0,85,128);

			.admin & {
				padding-top: 0;
				font-weight: bold;
			}
		}
	}

	.admin & {
		&.form-group-highlighted {
			padding: 15px;
			margin: 8px 0 0 0;
		}
	}

	div[class^='col-'] .row + .row {
		margin-top: 8px;
	}

	.form-field & {
		margin: 0;
	}

	.form-condensed &,
	.form-condensed & .form-control-static {
		margin: 8px 0;
	}
}

.control-label {
	.disabled &,
	.form-horizontal .disabled & {
		&:extend(.disabled a);
	}

	.has-success & {
		color: @color1LightText;
	}
}

.disabled a {
	color: #bdbfc1;
}

.help-block,
.has-success .help-block,
.help-block .btn {
	color: #aaa;
}

.help-block {
	margin-bottom: 0;

	.btn {
		position: relative;
		top: -3px;
	}
}

.form-field {
	margin: 20px 0 15px 0;
}

.required-group .required:after {
	content: "*";
	color: red;
	position: absolute;
	right:5px;
	top: 0;
	font-size: 18px;
}

.has-error .help-block,
.has-error .control-label,
.error-field {
	color: @warningColor !important;
	word-break: break-word;
}

.has-error input[type="text"] {
	background: @white;
}

.error-field {
	border-left: 0 !important;
	display: none;

	.has-error & {
		display: block;

		&:before {
		    content: "\E197";
		    color: #af0000;
		    font: 13px/1em @glyphiconFont;
			margin-right: 3px;
		    font-style: normal;
		    font-weight: 400;
		    vertical-align: middle;
		    text-transform: none;
		    -webkit-font-smoothing: antialiased;
		}
	}
}

.has-success .required-field,
.has-success .error-field,
.has-error .required-field,
.has-error .success-field,
.required .error-field,
.required .success-field {
    display: none;
}

.required {
	.required-field {
		&:before {
			content: "*";
			color: #af0000;
			position: absolute;
			left: 0;
			top: 2px;
			display: inline-block;
			margin: 5px 5px 0 0;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			vertical-align: middle;
			text-transform: none;
			-webkit-font-smoothing: antialiased;
		}

		&.required-field-inline:before{
			position: static;
			margin: 0;
		}
	}
}


@media(max-width:767px){
	.required .required-field {
		position: absolute;
		top: -2px;
		right: -20px;
	}
}

input[type="radio"],
input[type="checkbox"] {
	margin-right: 5px;
}

fieldset.primary {
	.form-group {
		padding: 12px 0 0 0;
		margin-bottom: 0;
	}

	.form-control.large {
		font-size: @type16;
		font-weight: 400;

		&-title {
		    font-size: 24px;
		    font-weight: 700;
		}
	}
}

.error-block {
	.media .ng-invalid + & {
	    display: block;
	}

	.media & {
		display: none;
	    padding-top: 8px;
	    color: @warningColor;
	    font-weight: bold;
	}

	input.ng-pristine + & {
		display: none;
	}
}

.form-inline {
	.has-error {
	    background-color: fade(@black, 0%);

	    .error-field {
			position: relative;

			&:before {
			    left: 4px;
			}
		}
	}

	.required .required-field,
	.has-success .success-field {
	    display: inline;
	    position: relative;
	}

	.error-field label {
	    padding-left: 24px;
	}

	.required .required-field:before {
	    top: -5px;
	    left: 4px;
	}
}


form fieldset {
	border-bottom: 1px solid @borderColorLight;
	padding: 8px 0;
	margin: 0;

	&:last-child {
		border-bottom: 0;
	}
}

.hidden-submit{
	display: block;
	height: 0;
	width: 0;
	margin: 0;
	padding: 0;
	border-width: 0;
}
